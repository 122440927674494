import React, { Component } from 'react';
import videojs from 'video.js';
import 'videojs-playlist';
import 'video.js/dist/video-js.css';

class VideoWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            linksLoaded: false,
            videoLinks: []
        }
    }

    componentDidMount() {
        const { item } = this.props;

        if (item && item.id && item.videoUrls) {
            this.setState({
                linksLoaded: true,
                videoLinks: item.videoUrls
            }, () => {
                this.initVideoPlayer();
            });
        }
    }

    initVideoPlayer() {
        const { videoLinks } = this.state;
        const { item , autoplay } = this.props;

        const videoJsOptions = {
            autoplay: autoplay || false,
            controls: true,
            poster: item.poster || item.archiveCustomCover,
            inactivityTimeout: 0,
            html5: {
                nativeTextTracks: false,
                featuresNativeTextTracks: true,
            },
            textTrackSettings: true,
            playsinline: true,
            controlBar: {
                children: [
                    'playToggle',
                    'volumePanel',
                    'currentTimeDisplay',
                    'timeDivider',
                    'durationDisplay',
                    'progressControl',
                    'liveDisplay',
                    'seekToLive',
                    'remainingTimeDisplay',
                    'customControlSpacer',
                    'playbackRateMenuButton',
                    'chaptersButton',
                    'descriptionsButton',
                    'subsCapsButton',
                    'audioTrackButton',
                    'fullscreenToggle',
                    'buttonChildOption'
                ]
            },
            sources: [{
                src: videoLinks && videoLinks.length ? videoLinks[0].url : null
            }],
            tracks: videoLinks && videoLinks.length && videoLinks[0] && videoLinks[0].captions && videoLinks[0].captions.length ? videoLinks[0].captions : []
        }

        // instantiate Video.js
        this.player = videojs(this.videoNode, videoJsOptions, () => {
            console.log('onPlayerReady', this)
            window.scrollTo(0, 0);

            if (videoLinks && videoLinks.length && videoLinks.length > 1) {
                let videoPlaylist = [];
                videoLinks.map((item) => {
                    let sourceItem = {
                        sources: [{
                            src: item.url
                        }],
                        tracks: item && item.captions && item.captions.length ? item.captions : null,
                        poster: item.poster || item.archiveCustomCover
                    }
                    videoPlaylist.push(sourceItem);
                    return item;
                });

                this.player.playlist(videoPlaylist);

                // Play through the playlist automatically.
                this.player.playlist.autoadvance(0);
                // this.player.playlist.repeat(true);

                var playList = this.player.playlist;

                var buttonComponent = videojs.getComponent('Button');

                var prevButton = videojs.extend(buttonComponent, {
                    constructor: function () {
                        buttonComponent.apply(this, arguments);
                        this.addClass('vjs-icon-previous-item');
                        this.controlText('Previous');
                    },
                    handleClick: function (e) {
                        playList.previous();
                    }
                });

                var nextButton = videojs.extend(buttonComponent, {
                    constructor: function () {
                        buttonComponent.apply(this, arguments);
                        this.addClass('vjs-icon-next-item');
                        this.controlText('Next');
                    },
                    handleClick: function (e) {
                        playList.next();
                    }
                });

                videojs.registerComponent('prevButton', prevButton);
                videojs.registerComponent('nextButton', nextButton);

                this.player.getChild('controlBar').addChild('prevButton', {}, 0);
                this.player.getChild('controlBar').addChild('nextButton', {}, 2);
            }

        });

        this.player.on(['waiting', 'pause'], () => {
            this.setState({
                isPlaying: false
            });
        });

        this.player.on(['playerresize'], () => {
            let video = this.player.currentDimensions();
            this.player.dimensions(video.width, video.height);
        });

        this.player.on('playing', () => {
            this.setState({
                isPlaying: true
            });
        });

        this.player.on('loadeddata', () => {
            let value = this.player.duration();
            console.log('duration', parseInt(value));
        });

        this.player.on('ended', () => {
            // this.player.src({src: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'});
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    handleBack() {
        const { closeVideoWrapper } = this.props;

        if (closeVideoWrapper) {
            closeVideoWrapper();
        }
    }

    render() {
        const { linksLoaded } = this.state;

        return (
            <div className='payment-wrapper'>
                {linksLoaded ?
                    <div className='payment-form video-form'>
                        <div className='payment-div'>
                            <video ref={node => this.videoNode = node}
                                className="video-js vjs-big-play-centered"
                                playsInline={true}
                            />
                        </div>
                    </div>
                    :
                    <div
                        text="Loading video..."
                    />
                }
            </div>
        )
    }
}

export default VideoWrapper;