import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField
} from "react-admin";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";

export const StatsShow = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TableContainer
                    sx={{
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                    component={Paper}
                >
                    <Table size="small">
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Created Events
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="createdEvents" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    User Count
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="userCount" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Event Minutes
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="eventMinutes" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Event Count
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="eventCount" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Active Creators In Week
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    source="activeCreatorsInAWeek"
                                    label={"Active Creators /w"}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Total Created Events
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="totalCreatedEvents" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Total Event Count
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="totalEventCount" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Total Event Minutes
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="totalEventMinutes" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: 300 }}
                            >
                                <Typography component="span" variant="body2">
                                    Total User Count
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField source="totalUserCount" />
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            </SimpleShowLayout>
        </Show>
    );
}