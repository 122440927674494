// in src/LoginPage.js
import { Stack, Typography as Text } from "@mui/material";
import * as React from "react";
import { Button, Login, useLogin, useNotify } from "react-admin";
import { ReactComponent as MyLogo } from "../../assets/google.svg";

const LoginPage = (props) => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = (e) => {
        e.preventDefault();
        // firebaseClient.logIn('google')
        login({ adapter: "google" }).catch(() => notify("Could not login"));
    };

    return (
        <Login
            sx={{
                "& .MuiPaper-root": {
                    margin: "0",
                    position: "absolute",
                    top: "40%",
                    transform: "translateY(-60%)",
                    minWidth: "400px",
                    alignItems: "center",
                    padding: "30px",
                },
                "& .RaLogin-avatar": {
                    display: "none",
                },
            }}
            {...props}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Stack
                    direction="column"
                    spacing={3}
                    sx={{
                        padding: "20px",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <Text variant="h6">Control panel</Text>
                    <Button
                        color={"primary"}
                        onClick={handleSubmit}
                        variant="outlined"
                        sx={{
                            textTransform: "none",
                            minWidth: "300px",
                            padding: "10px",
                        }}
                    >
                        <Stack direction="row" spacing={2}>
                            <MyLogo height="20" />
                            <Text variant="body1" color="white">
                                Sign in with Google
                            </Text>
                        </Stack>
                    </Button>
                </Stack>
            </form>
        </Login>
    );
};

export default LoginPage;