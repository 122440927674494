export default class DataProvider {
    constructor(gapi, config) {
        this.gapi = gapi;
        this.config = config;
    }
    getList(resource, params) {
        let url = `https://compute.googleapis.com/compute/v1/projects/${this.config.projectId}/aggregated/${resource}`;

        let req = this.gapi.client.request({ path: url, method: 'get' });

        if (!req)
            return { error: `Call to gapi.client.request returned undefined.`, data: [], total: 0 };

        return req.then((rdata) => {
            console.log('getList data', rdata);
            let items = (rdata && rdata.result) ? rdata.result.items : {};
            let data = [];
            Object.entries(items).forEach(([zone, zoneItems]) => {
                data = data.concat(zoneItems.instances || [])
            });
            return { data, total: data.length };
        }).catch((e) => {
            console.error('Call to getList failed [%s]', e.message || 'unknown', resource, params, e);
            return { error: `Call to getList failed ${e.message}`, data: [], total: 0 };
        });
    }

    getOne(resource, params) {
        let url;
        url = `https://compute.googleapis.com/compute/v1/projects/${this.config.projectId}/aggregated/${resource}`;
        url = `https://www.googleapis.com/compute/v1/projects/${this.config.projectId}/zones/us-central1-a/${resource}/${params.id}`;

        let req = this.gapi.client.request({ path: url, method: 'get' });

        if (!req)
            return Promise.reject();

        return req.then((rdata) => {
            console.log('getOne data', rdata);
            return { data: rdata.result || null };
        }).catch((e) => {
            console.error('Call to getOne failed [%s]', e.message, resource, params, e);
            return { error: `Call to getList failed ${e.message}`, data: null };
        });
    }

    getMany(resource, params) {
        // const query = {
        //     filter: JSON.stringify({ ids: params.ids }),
        // };
        // const url = `${apiUrl}/${resource}?${stringify(query)}`;
        // return httpClient(url).then(({ json }) => ({ data: json }));
    }

    getManyReference(resource, params) {
        // const { page, perPage } = params.pagination;
        // const { field, order } = params.sort;
        // const query = {
        //     sort: JSON.stringify([field, order]),
        //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        //     filter: JSON.stringify({
        //         ...params.filter,
        //         [params.target]: params.id,
        //     }),
        // };
        // const url = `${apiUrl}/${resource}?${stringify(query)}`;
        //
        // return httpClient(url).then(({ headers, json }) => ({
        //     data: json,
        //     total: parseInt(headers.get('content-range').split('/').pop(), 10),
        // }));
    }

    update(resource, params) {
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({json}) => ({data: json}))
    }

    updateMany(resource, params) {
        // const query = {
        //     filter: JSON.stringify({ id: params.ids}),
        // };
        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json }));
    }

    create(resource, params) {
        // httpClient(`${apiUrl}/${resource}`, {
        //     method: 'POST',
        //     body: JSON.stringify(params.data),
        // }).then(({json}) => ({
        //     data: {...params.data, id: json.id},
        // }))
    }

    delete(resource, params) {
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({json}) => ({data: json}))
    }

    deleteMany(resource, params) {
        // const query = {
        //     filter: JSON.stringify({ id: params.ids}),
        // };
        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'DELETE',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json }));
    }

    restart(resource, { id, name, zone }) {
        switch (resource) {
            case 'instances':
                console.log('About to reset instance "%s" [%s]', name, id)
                return Promise.resolve({ data: {} });
            default: console.log('Default case');
        }
    }

    start(resource, { id, name }) {
        switch (resource) {
            case 'instances':
                console.log('About to start instance "%s" [%s]', name, id)
                return Promise.resolve({ data: {} });
            default: console.log('Default case');
        }
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({json}) => ({data: json}))
    }


    stop(resource, { id, name }) {
        switch (resource) {
            case 'instances':
                console.log('About to stop instance "%s" [%s]', name, id)
                return Promise.resolve({ data: {} });
            default: console.log('Default case');
        }
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({json}) => ({data: json}))
    }

    suspend(resource, { id, name, zone }) {
        switch (resource) {
            case 'instances':
                console.log('About to suspend instance "%s" [%s]', name, id);
                let url;
                url = `${zone}/${resource}/${name}/suspend`;

                let req = this.gapi.client.request({ path: url, method: 'post' });

                if (!req)
                    return { error: `Call to gapi.client.request returned undefined.`, data: {} };

                return req.then((rdata) => {
                    console.log('response data', rdata);
                    return { data: {} };
                }).catch((e) => {
                    console.error('Call to suspend failed [%s]', e.message || 'unknown', resource, name, zone, e);
                    return { error: `Call to suspend failed ${e.message}`, data: [], total: 0 };
                });
            default: console.log('Default case');
        }
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({json}) => ({data: json}))
    }


    resume(resource, { id, name }) {
        switch (resource) {
            case 'instances':
                console.log('About to resume instance "%s" [%s]', name, id);
                return Promise.resolve({ data: {} });
            default: console.log('Default case');
        }
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({json}) => ({data: json}))
    }

    async handleAction(action, resource, params) {
        switch (action) {
            case 'delete':
            case 'create':
            case 'stop':
            case 'start':
            case 'suspend':
            case 'resume': {
                const { id, name, zone } = params;
                switch (resource) {
                    case 'instances':
                        console.log('About to %s %s "%s" [%s]', action, resource, name, id);
                        let url;
                        let req;
                        let ret = { data: {} };
                        switch (action) {
                            case 'delete':
                                url = `${zone}/${resource}/${name}`;
                                req = this.gapi.client.request({ path: url, method: 'delete' });
                                break;
                            case 'create':
                                url = `${zone}/${resource}/`;
                                // req = this.gapi.client.request({path: url, method: 'post'});
                                let deviceName = "streams-fanout-" + Date.now();
                                let sourceMachineImage = "projects/streemz-net/global/machineImages/streemz-fanout-prod";
                                let machineType = "projects/streemz-net/zones/us-central1-a/machineTypes/c2-standard-4"
                                let cparams = {
                                    "canIpForward": false,
                                    "confidentialInstanceConfig": {
                                        "enableConfidentialCompute": false
                                    },
                                    "deletionProtection": false,
                                    "description": "",
                                    "disks": [
                                        {
                                            "autoDelete": true,
                                            "boot": true,
                                            "deviceName": deviceName,
                                            "initializeParams": {
                                                "diskSizeGb": "20",
                                                "diskType": "projects/streemz-net/zones/us-central1-a/diskTypes/pd-ssd",
                                                "labels": {},
                                                "sourceImage": "projects/centos-cloud/global/images/centos-7-v20220406"
                                            },
                                            "mode": "READ_WRITE",
                                            "type": "PERSISTENT"
                                        }
                                    ],
                                    "displayDevice": {
                                        "enableDisplay": false
                                    },
                                    "guestAccelerators": [],
                                    "keyRevocationActionType": "NONE",
                                    "labels": {},
                                    "machineType": machineType,
                                    "metadata": {
                                        "items": []
                                    },
                                    "minCpuPlatform": "Automatic",
                                    "name": deviceName,
                                    "networkInterfaces": [
                                        {
                                            "accessConfigs": [
                                                {
                                                    "name": "External NAT",
                                                    "networkTier": "PREMIUM"
                                                }
                                            ],
                                            "stackType": "IPV4_ONLY",
                                            "subnetwork": "projects/streemz-net/regions/us-central1/subnetworks/default"
                                        }
                                    ],
                                    "reservationAffinity": {
                                        "consumeReservationType": "ANY_RESERVATION"
                                    },
                                    "scheduling": {
                                        "automaticRestart": true,
                                        "onHostMaintenance": "MIGRATE",
                                        "preemptible": false,
                                        "provisioningModel": "STANDARD"
                                    },
                                    "serviceAccounts": [
                                        {
                                            "email": "104795671843-compute@developer.gserviceaccount.com",
                                            "scopes": [
                                                "https://www.googleapis.com/auth/devstorage.read_only",
                                                "https://www.googleapis.com/auth/logging.write",
                                                "https://www.googleapis.com/auth/monitoring.write",
                                                "https://www.googleapis.com/auth/servicecontrol",
                                                "https://www.googleapis.com/auth/service.management.readonly",
                                                "https://www.googleapis.com/auth/trace.append"
                                            ]
                                        }
                                    ],
                                    "shieldedInstanceConfig": {
                                        "enableIntegrityMonitoring": true,
                                        "enableSecureBoot": false,
                                        "enableVtpm": true
                                    },
                                    "sourceMachineImage": sourceMachineImage,
                                    "tags": {
                                        "items": [
                                            "http-server",
                                            "https-server"
                                        ]
                                    },
                                    "zone": "projects/streemz-net/zones/us-central1-a"
                                };
                                console.log('About to send request ', { path: url, method: 'post', body: cparams })
                                req = this.gapi.client.request({ path: url, method: 'post', body: cparams });
                                break;
                            default:
                                url = `${zone}/${resource}/${name}/${action}`;
                                req = this.gapi.client.request({ path: url, method: 'post' });
                        }

                        if (!req)
                            return { error: `Call to gapi.client.request returned undefined.`, data: {} };

                        return req.then((rdata) => {
                            console.log('response data', rdata);
                            return ret;
                        }).catch((e) => {
                            console.error('Call to %s failed [%s]', action, e.message || 'unknown', resource, name, zone, e);
                            return Promise.resolve({ error: `Call to ${action} failed ${e.message}`, data: [], total: 0 });
                        });
                    default: console.log('Default case');
                }
                break;
            }
            default:
                if (this[action])
                    this[action](resource, params);
                break;
        }
    }
}