import * as React from "react";
import { useEffect, useState } from "react";
import {
    useRecordContext,
    useRedirect
} from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import { getTicketStore } from "../../../utils/HelperFunctions";
import moment from "moment/moment";
import { DataGrid } from "@mui/x-data-grid";
import get from 'lodash/get';
import CopyToClipboardButton from "../../common/CopyToClippboardButton";

// eslint-disable-next-line
export default ({ source, fieldName }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        if (doc && doc.data) {
            let filtered = doc.data.map((row) => {
                let date = null;
                if (row.paymentDate) {
                    const m = moment(row.paymentDate);
                    date = m.format("L") + ' ' + m.format("LTS");
                }
                const updatedRow = {
                    id: row.id,
                    date,
                    author: row.author || row.authorId,
                    user: row.user || row.userId,
                    status: row.paymentStatus || row.status,
                    title: row.title || row.eventId,
                    price: row.price ? `$${row.price}` : "",
                    store: row.id ? getTicketStore(row.id) : ""
                };
                return updatedRow;
            });
            console.log('filtered', filtered);
            setDoc(filtered || null);
        } else {
            setDoc(null);
        }
        setLoaded(true);
    };

    useEffect(() => {

        let params = {
            collection: '/tickets',
            where: {
                fieldPath: 'type',
                optStr: 'in',
                value: ['tip']
            },
            where2: {
                fieldPath: fieldName,
                optStr: '==',
                value: get(record, source)
            }
        };

        firebaseClient
            .getAllSearchObject(params)
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            });
        return () => { };
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            sort: 'desc'
        },
        {
            field: 'id',
            headerName: 'Id',
            minWidth: 300,
            maxWidth: 400,
            flex: 1,
            renderCell: (params) =>  <div className="data-cell__copy-to-clipboard"><span>{params.id}</span><CopyToClipboardButton string source={params.id} className="launch-link"/></div>
        },
        {
            field: 'title',
            headerName: 'Show',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
        },
        {
            field: 'author',
            headerName: 'Author',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
        },
        {
            field: 'user',
            headerName: 'User',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Amount',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        },
        {
            field: 'store',
            headerName: 'Store',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        }
    ];

    return record && record.id && doc ? (
        <div style={{ display: 'flex', height: '100%', minHeight: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {!doc || !doc.length ? (
                    'No tips found'
                ) : (
                        <React.Fragment>
                            <DataGrid
                                disableColumnMenu={true}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    border: 0,
                                    '& .MuiDataGrid-columnSeparator--sideRight': {
                                        display: 'none'
                                    },
                                    '& .MuiDataGrid-columnHeaders + div': {
                                        height: 'auto !important',
                                        minHeight: '100px'
                                    },
                                    '& .MuiDataGrid-virtualScroller': {
                                        height: 'auto !important'
                                    }
                                }}
                                getRowId={(row) => row.id}
                                rows={doc}
                                columns={columns}
                                pagination={false}
                                pageSize={100}
                                withBorder={false}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'date', sort: 'DESC' }]
                                    }
                                }}
                                onRowClick={({ id }) => { redirect(`/tips/${id}/show`) }}
                            />
                        </React.Fragment>
                    )}
            </div>
        </div>
    ) : (
            <div>
                {error
                    ? error
                    : !loaded
                        ? "Loading..."
                        : "No tips for filter"}
            </div>
        );
};