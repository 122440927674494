import * as React from "react";
import { useEffect } from "react";
import {
    Datagrid,
    List,
    TextField,
    useRefresh,
    downloadCSV,
} from "react-admin";
import FSDateField from "../../common/FSDateField";
import jsonExport from 'jsonexport/dist';

export const StatsList = () => {
    const refresh = useRefresh();
    const refreshInterval = 12 * 60 * 60 * 1000; // 12 hours

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refreshInterval, refresh]);

    const exporter = (data) => {
        let dump =
            data.map(item => {
                const { dateFrom, dateTo, createdEvents, userCount,
                    eventMinutes, eventCount, activeCreatorsInAWeek,
                    totalCreatedEvents, totalUserCount,
                    totalEventMinutes, totalEventCount
                } = item;
                return {
                    dateFrom: new Date(dateFrom._seconds * 1000).toLocaleDateString(),
                    dateTo: new Date(dateTo._seconds * 1000).toLocaleDateString(),
                    createdEvents, userCount,
                    eventMinutes, eventCount, activeCreatorsInAWeek,
                    totalCreatedEvents, totalUserCount,
                    totalEventMinutes, totalEventCount
                }
            });
        jsonExport(dump, {
            headers: ['dateTo', 'createdEvents', 'userCount',
                'eventMinutes', 'eventCount', 'activeCreatorsInAWeek',
                'totalCreatedEvents', 'totalUserCount',
                'totalEventMinutes', 'totalEventCount'
            ] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'stats'); // download as 'posts.csv` file
        });
    }

    return (
        <List
            exporter={exporter}
            sort={{ field: "dateTo", order: "DESC" }}
            actions={null}
        >
            <Datagrid optimized rowClick="show" bulkActionButtons={false}>
                {/* <TextField source="id" /> */}
                <FSDateField source="dateFrom" label="From" sortable={false} locales="en-US"/>
                <FSDateField source="dateTo" label="To" sortable={false} locales="en-US" />
                <TextField source="createdEvents" sortable={false} />
                <TextField source="userCount" sortable={false} />
                <TextField source="eventMinutes" sortable={false} />
                <TextField source="eventCount" sortable={false} />
                <TextField source="activeCreatorsInAWeek" label={"Active Creators /w"} sortable={false} />
            </Datagrid>
        </List>
    );
};