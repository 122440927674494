import { useRecordContext } from "react-admin";
import moment from "moment/moment";
import * as React from "react";
import get from 'lodash/get';

const FSDateField = ({ source }) => {
    const record = useRecordContext();
    return record ? <span>{moment(get(record, source)._seconds * 1000).format("L")}</span> : null;
}
FSDateField.defaultProps = { label: 'Date' };

export default FSDateField;