import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import CircularProgress from '@mui/material/CircularProgress';
import {
    Datagrid,
    List,
    TextField,
    useRefresh,
    useRecordContext,
    SimpleForm,
    regex,
    TextInput,
    Toolbar,
    // SaveButton,
    useRedirect,
    Button
} from "react-admin";
import { makeid } from '../../../utils/HelperFunctions';
import firebaseClient from "../../../utils/FirebaseClient";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import FSDateTimeField from "../../common/FSDateTimeField";
import TimestampDateTimeField from "../../common/TimestampDateTimeField";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useFormContext } from "react-hook-form";

const CopyField = ({ source }) => {
    const record = useRecordContext();
    return <div className="data-cell__copy-to-clipboard">
        <span>{record[`${source}`]}</span>
        <CopyToClipboardButton string source={record[`${source}`]} className="launch-link" />
    </div>;
};
CopyField.defaultProps = {
    textAlign: 'left',
};

const SummaryField = () => {
    const record = useRecordContext();
    const { summary } = record;
    return (
        <span>
            {summary && summary.summary ? "Yes" : "No"}
        </span>
    );
}
SummaryField.defaultProps = { label: 'Summary' };

const MainTopicsField = () => {
    const record = useRecordContext();
    const { summary } = record;
    return (
        <span>
            {summary && summary.main_topics && summary.main_topics.length && Array.isArray(summary.main_topics) ? "Yes" : "No"}
        </span>
    );
}
MainTopicsField.defaultProps = { label: 'Main Topics' };

const TranscriptField = () => {
    const record = useRecordContext();
    const { transcript } = record;
    return (
        <span>
            {transcript ? "Yes" : "No"}
        </span>
    );
}
TranscriptField.defaultProps = { label: 'Transcript' };

export const AIList = () => {
    const refresh = useRefresh();
    const refreshInterval = 60 * 1000; // 60 seconds
    const redirect = useRedirect();
    const addFileRef = useRef();
    const [mediaUrl, setMediaUrl] = useState('');
    const [uploding, setUploading] = useState(false);
    const [processing, setProcessing] = useState(false);
    // eslint-disable-next-line
    const validateLink = regex(/(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/, 'Must be a valid link');

    const handleSubmit = async () => {
        // console.log("handleSubmit", value);

        if (mediaUrl) {
            setProcessing(true);
            return firebaseClient.summarizeURL(mediaUrl).then((res) => {
                // console.log('SUMMARY', res);
                setProcessing(false);
                if (res && res.id) {
                    redirect(`/ai/${res.id}/show`);
                }
            }).catch((e) => console.error(e));
        }
    };

    const FileButton = () => {
        const { setValue } = useFormContext();
        return (
            <React.Fragment>
                <Button
                    onClick={() => handleClickAddImage()}
                    title="Upload File"
                    disabled={processing || uploding}
                    sx={{
                        "& ": {
                            margin: '0',
                            display: 'block',
                            position: 'absolute',
                            padding: '10px 14px',
                            right: 0,
                            top: '16px',
                            width: '110px'
                        },
                        "& .MuiButton-startIcon": {
                            margin: 0
                        }
                    }}
                >
                    {!uploding ?
                        <span style={{ fontSize: '16px', textTransform: 'capitalize', lineHeight: '14px' }}>Upload <UploadFileIcon fontSize="inherit" /></span>
                        :
                        <span style={{ fontSize: '16px', textTransform: 'capitalize' }}>Uploading...</span>
                        // <CircularProgress
                        //     style={{ width: '18px', height: '18px', verticalAlign: 'middle' }}
                        // />
                    }
                </Button>
                <input
                    type="file"
                    className='add-file'
                    style={{ display: 'none' }}
                    ref={addFileRef}
                    accept="audio/mp3,video/mp4"
                    onChange={(event) => handleAddFile(event, (val) => setValue('mediaUrl', val))}
                />
            </React.Fragment>
        )
    }

    function handleClickAddImage() {
        if (addFileRef && addFileRef.current && addFileRef.current.click) {
            addFileRef.current.value = null;
            addFileRef.current.click();
        }
    }

    function handleAddFile(event, callback) {
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0], path = `AI/${makeid(20)}`;

            if (file) {
                setUploading(true);
                firebaseClient.addFileToStorage(file, path).then((res) => {
                    console.log('addFileToStorage-URL', res);
                    if (res) {
                        setMediaUrl(res);
                        callback(res);
                    }
                    setUploading(false);
                }).catch((e) => {
                    console.error(e);
                    setUploading(false);
                })
            }
        }
    }

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refresh, refreshInterval]);

    return (
        <div>
            <SimpleForm
                toolbar={null}
                onSubmit={handleSubmit}
                sx={{
                    "& ": {
                        width: '100%',
                        padding: '20px 0 0 0 !important',

                    }
                }}
            >
                <Stack
                    sx={{
                        "& ": {
                            width: '100%'
                        }
                    }}
                >
                    <Typography
                        variant="h7"
                        gutterBottom
                        sx={{
                            "& ": {
                                marginBottom: '15px'
                            }
                        }}
                    >
                        Summarize
                    </Typography>
                    <Stack
                        style={{
                            position: 'relative'
                        }}
                    >
                        <TextInput
                            name='mediaUrl'
                            value={mediaUrl}
                            onChange={(e) => {
                                setMediaUrl(e?.target?.value || '');
                            }}
                            label='Media URL (url to mp3 or mp4 file)'
                            fullWidth
                            resettable
                            validate={validateLink}
                            disabled={processing || uploding}
                            sx={{
                                "& ": {
                                    width: '100%',
                                    marginTop: '16px !important',
                                    paddingRight: '110px'
                                }
                            }}
                        />
                        <FileButton />
                    </Stack>
                    <Toolbar sx={{
                        "& ": {
                            background: "transparent",
                            padding: '0',
                            height: 'auto'
                        },
                    }}>
                        <Stack sx={{ "& ": { justifyContent: 'space-between' } }}>
                            {/* <SaveButton
                                icon={null}
                                label="Process"
                                disabled={processing || uploding}
                            /> */}
                            <Button
                                type="submit"
                                color={"primary"}
                                label={processing ? 'Processing...' : 'Process'}
                                variant="contained"
                                disabled={processing || uploding || !mediaUrl}
                                sx={{
                                    "& ": {
                                        padding: '7px 14px'
                                    }
                                }}
                            />
                        </Stack>
                    </Toolbar>
                </Stack>
            </SimpleForm>
            <List
                pagination={false}
                perPage={100}
                sort={{ field: "timestamp", order: "DESC" }}
                actions={null}
            >
                <Datagrid
                    optimized
                    rowClick="show"
                    bulkActionButtons={false}
                >
                    <CopyField source="id" sortable={false} />
                    <TextField source="status" sortable={false} style={{ textTransform: 'uppercase' }} />
                    <TimestampDateTimeField source="timestamp" label="Date" showTime={true} sortable={false} locales="en-US" emptyText="none" />
                    <FSDateTimeField source="expires" label="Expires" sortable={false} locales="en-US" />
                    <SummaryField />
                    <MainTopicsField />
                    <TranscriptField />
                </Datagrid>
            </List>
        </div>
    );
};
