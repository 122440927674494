import * as React from "react";
import { useEffect, useState } from "react";
import {
    Button,
    Confirm,
    ListButton,
    Show,
    SimpleShowLayout,
    TopToolbar,
    useDataProvider,
    useRecordContext,
} from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import { Link } from '@material-ui/core';

const RestartFanoutButton = (props) => {
    // const { record } = props;
    const [open, setOpen] = useState(false);
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        let { id } = record;
        console.log('got records', record, id);
        if (!id) {
            setOpen(false);
            return;
        }
        dataProvider.restart('fanouts', { id }).then(() => {
            setOpen(false);
        });
    };

    return (
        <>
            <Button label="Restart fanout" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Suspend instance"
                content="Are you sure you want to restart fanout?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

const FanoutShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        {/*<ShowButton basePath={basePath} record={data} />*/}
        {/* Add your custom actions */}
        <RestartFanoutButton record={data} color="primary">
            Restart
        </RestartFanoutButton>
    </TopToolbar>
);

const Stats = ({ eventId }) => {
    const record = useRecordContext();
    // console.log(record);
    const [stats, setStats] = useState(null);

    const onStatUpdate = (snapshot) => {
        console.log("---------", snapshot);
        const data = snapshot.val();
        console.log("Got RT data ", data, record.id);
        setStats(data);
    };

    useEffect(() => {
        firebaseClient.attachRTListener(
            `/fanout/instances/${record.id}`,
            onStatUpdate
        );
        return () =>
            firebaseClient.detachRTListener(
                `/fanout/instances/${record.id}`,
                onStatUpdate
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return record && record.id && stats ? (
        <TableContainer
            sx={{
                padding: "30px",
            }}
            component={Paper}
        >
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        ID
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <div className="data-cell__copy-to-clipboard">
                            <span>{record.id}</span>
                            <CopyToClipboardButton string source={record.id} className="launch-link" />
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Event Id
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <div className="data-cell__copy-to-clipboard">
                            <Link href={`/#/${record.status === 'scheduled' ? 'events' : 'posts'}/${record.eventId}/show`} className="launch-link" >
                                {record.eventId}
                            </Link>
                            <CopyToClipboardButton string source={record.eventId} className="launch-link" />
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Status
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.state}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Services
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.services}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Compositing
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.compositing}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Max idle
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.maxIdleCpus}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Total conference
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.totalConferences}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Total idle
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.totalIdleCpu}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Total viewers
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.totalViewers}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Updated
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.timestamp !== undefined
                            ? moment(
                                (stats.data.timestamp)
                            ).format("MM/DD/YYYY HH:mm:ss")
                            : null}
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    ) : (
            <div>No data for this fanout</div>
        );
};

export const FanoutShow = (props) => {
    return (
        <Show actions={<FanoutShowActions />} {...props}>
            <SimpleShowLayout>
                <Stats />
            </SimpleShowLayout>
        </Show>
    );
};