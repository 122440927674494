import * as React from 'react';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
    CheckboxGroupInput,
    RadioButtonGroupInput,
    BooleanInput
} from 'react-admin';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    ArrayField,
    BooleanField,
    DateField,
    EmailField,
    ImageField,
    ListButton,
    NumberField,
    RichTextField,
    FunctionField,
    Show,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext,
    useRefresh,
    SaveButton,
    Toolbar,
    Button,
    SimpleForm
} from 'react-admin';
import firebaseClient from '../../../utils/FirebaseClient';
import { Link } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CopyToClipboardButton from '../../common/CopyToClippboardButton';
import LaunchIcon from '@mui/icons-material/Launch';
import Stack from "@mui/material/Stack";
import TicketsFilteredList from "../tickets/FilteredList";
import FanoutsFilteredList from "../fanouts/FilteredList";
import CropperFile from '../../common/CropperFile';
import { getTenantId, isB2B } from '../../../utils/HelperFunctions';

const theme = createTheme({
    palette: {
        mode: 'dark'
    },
    components: {
        MuiTableRow: {
            defaultProps: {
                hover: true
            }
        }
    }
});

const MainTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return "[resource_name] #[record_id]";
    return <span>Event: "{record.title}" #{record.id}</span>;
};

function showLayoutName(name) {
    let retVal = '';

    switch (name) {
        case 'ad-participants':
            retVal = 'Ad+Participants';
            break;
        case 'speaker':
            retVal = 'Ad+Active Speaker';
            break;
        case 'crop':
            retVal = 'Zoom in';
            break;
        case 'no-crop':
            retVal = 'Zoom out';
            break;
        default:
            retVal = '';
            break;
    }

    return retVal;
}

const StreamLayoutField = (props) => {
    const record = useRecordContext(props);
    return record && record.callParams && record.callParams.layoutParams && record.callParams.layoutParams.defaultLayout ? <span>{showLayoutName(record.callParams.layoutParams.defaultLayout)}</span> : null;
}

const InstanceShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

const GeneralInfoTable = ({ eventId }) => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const [doc, setDoc] = useState(null);
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate event", doc);
        let event = doc ? doc.data : null;

        if (event) {
            let banners = event.callParams && event.callParams.layoutParams && event.callParams.layoutParams.banners && event.callParams.layoutParams.banners.length ? event.callParams.layoutParams.banners : [], banner = null;

            if (banners && banners.length) {
                banner = banners.filter(item => item.default)[0];

                if (!banner) {
                    banner = banners[0];
                }

                event.banner = banner
                event.bannerImg = banner.img;
            }
            setDoc(event || null);
        } else {
            setDoc(null)
        }
        setLoaded(true);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        firebaseClient.readObject(getTenantId('calendar'), record.id)
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            })
            ;
        return () => {
        };
    }, [record.id]);

    const HiddenInToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenHiddenIn(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const BannersToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenBanners(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const VisibilityToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenVisibility(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const VersionToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenVersion(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const StreamLayoutToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenStreamLayout(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const VideoLabelsToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenVideoLabels(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const StatusToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenStatus(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const LiveToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenLive(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const ArchiveEnabledToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenArchiveEnabled(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const AcceptTipsToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenAcceptTips(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const TypeToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenType(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const PopularToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenPopular(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const [openHiddenIn, setOpenHiddenIn] = React.useState(false);
    const [openBanners, setOpenBanners] = React.useState(false);
    const [openVisibility, setOpenVisibility] = React.useState(false);
    const [openVersion, setOpenVersion] = React.useState(false);
    const [openStreamLayout, setOpenStreamLayout] = React.useState(false);
    const [openVideoLabels, setOpenVideoLabels] = React.useState(false);
    const [openStatus, setOpenStatus] = React.useState(false);
    const [openLive, setOpenLive] = React.useState(false);
    const [openArchiveEnabled, setOpenArchiveEnabled] = React.useState(false);
    const [openAcceptTips, setOpenAcceptTips] = React.useState(false);
    const [openType, setOpenType] = React.useState(false);
    const [openPopular, setOpenPopular] = React.useState(false);

    const handleSubmitHiddenIn = async (data) => {
        console.log("About to save hiddenIn", data);
        await firebaseClient.update(getTenantId('posts'), { hiddenIn: data.hiddenIn }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { hiddenIn: data.hiddenIn }, data.id);
        refresh();
        setOpenHiddenIn(false);
    };
    const handleSubmitBanners = async (data) => {
        console.log("About to save banners", data.bannerLink, doc.bannerImg);

        let oldBanners = [], oldDefaultBanner = {}, newBanner = {};

        if (data.callParams && data.callParams.layoutParams && data.callParams.layoutParams.banners && data.callParams.layoutParams.banners.length) {
            oldBanners = data.callParams.layoutParams.banners;
        }

        if (oldBanners && oldBanners.length) {
            oldDefaultBanner = oldBanners.filter(item => item.default)[0];

            if (!oldDefaultBanner) {
                oldDefaultBanner = oldBanners[0];
            }
        }

        newBanner = Object.assign(oldDefaultBanner, {
            img: doc.bannerImg,
            link: data.bannerLink
        });

        data.callParams.layoutParams.banners = [newBanner];
        doc.banner = newBanner;

        await firebaseClient.update(getTenantId('posts'), { callParams: data.callParams }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { callParams: data.callParams }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}/callParams/layoutParams`, { banners: data.callParams.layoutParams.banners });
        }

        refresh();
        setDoc(doc);
        setOpenBanners(false);
    };
    const handleSubmitVisibility = async (data) => {
        console.log("About to save visibility", data);
        await firebaseClient.update(getTenantId('posts'), { visibility: data.visibility }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { visibility: data.visibility }, data.id);
        refresh();
        setOpenVisibility(false);
    };
    const handleSubmitVersion = async (data) => {
        console.log("About to save version", data);
        await firebaseClient.update(getTenantId('posts'), { version: data.version }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { version: data.version }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}`, { version: data.version });
        }

        refresh();
        setOpenVersion(false);
    };
    const handleSubmitStreamLayout = async (data) => {
        console.log("About to save stream layout", data);
        await firebaseClient.update(getTenantId('posts'), { callParams: data.callParams }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { callParams: data.callParams }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}/callParams/layoutParams`, { defaultLayout: data.callParams.layoutParams.defaultLayout });
        }

        refresh();
        setOpenStreamLayout(false);
    };
    const handleSubmitVideoLabels = async (data) => {
        console.log("About to save participant name labels", data);
        await firebaseClient.update(getTenantId('posts'), { callParams: data.callParams }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { callParams: data.callParams }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}/callParams/layoutParams`, { labels: data.callParams.layoutParams.labels });
        }

        refresh();
        setOpenVideoLabels(false);
    };
    const handleSubmitLive = async (data) => {
        console.log("About to save live", data);
        await firebaseClient.update(getTenantId('posts'), { live: data.live }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { live: data.live }, data.id);
        refresh();
        setOpenLive(false);
    };
    const handleSubmitArchiveEnabled = async (data) => {
        console.log("About to save archiveEnabled", data);
        await firebaseClient.update(getTenantId('posts'), { archiveEnabled: data.archiveEnabled }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { archiveEnabled: data.archiveEnabled }, data.id);
        refresh();
        setOpenArchiveEnabled(false);
    };
    const handleSubmitAcceptTips = async (data) => {
        console.log("About to save acceptTips", data);
        await firebaseClient.update(getTenantId('posts'), { acceptTips: data.acceptTips }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { acceptTips: data.acceptTips }, data.id);
        refresh();
        setOpenAcceptTips(false);
    };
    const handleSubmitStatus = async (data) => {
        console.log("About to save status", data);
        await firebaseClient.update(getTenantId('posts'), { status: data.status }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { status: data.status }, data.id);
        refresh();
        setOpenStatus(false);
    };
    const handleSubmitPopular = async (data) => {
        console.log("About to save featured", data);
        await firebaseClient.update(getTenantId('posts'), { featured: data.featured }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { featured: data.featured }, data.id);
        refresh();
        setOpenPopular(false);
    };

    const handleSubmitType = async (data) => {
        console.log("About to save type", data);
        await firebaseClient.update(getTenantId('posts'), { type: data.type }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { type: data.type }, data.id);

        if (data && data.type && data.type === 'test-event') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}`, { type: data.type });
        } else {
            await firebaseClient.crudRT('update', `protected/event/${data.id}`, { type: data.type });
        }

        refresh();
        setOpenType(false);
    };

    const handleAddImage = (image) => {
        console.log('handleAddImage', image)
        doc.bannerImg = image;
        setDoc(doc);
    }

    return (
        <TableContainer
            sx={{
                marginTop: '20px',
                marginBottom: '20px'
            }}
            component={Paper}
        >
            <Dialog open={openHiddenIn} onClose={() => setOpenHiddenIn(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Hide event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<HiddenInToolbar />}
                        onSubmit={handleSubmitHiddenIn}
                    >
                        <CheckboxGroupInput
                            source="hiddenIn"
                            choices={[
                                { id: 'upcoming', name: 'Upcoming' },
                                { id: 'rotator', name: 'Rotator' },
                                { id: 'vault', name: 'Vault' },
                            ]}
                            row={false}
                            initialValue={record.hiddenIn}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openBanners} onClose={() => setOpenBanners(false)}  >
                {/* <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Banner:
                </DialogTitle> */}
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    }
                }}>
                    <div style={{ padding: '16px 16px 0 16px' }}>
                        <p>Banner image</p>
                        <CropperFile
                            file={doc && doc.banner && doc.banner.img ? doc.banner.img : null}
                            uid={record && record.uid ? record.uid : null}
                            handleAddImage={(image) => handleAddImage(image)}
                        />
                    </div>
                    <SimpleForm
                        toolbar={<BannersToolbar />}
                        onSubmit={handleSubmitBanners}
                        sx={{
                            "& ": {
                                paddingTop: '0'
                            }
                        }}
                    >
                        <p>Banner link</p>
                        <TextInput
                            source='bannerLink'
                            label=''
                            defaultValue={doc && doc.banner && doc.banner.link ? doc.banner.link : ''}
                            fullWidth
                            resettable
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openVisibility} onClose={() => setOpenVisibility(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Visibility event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<VisibilityToolbar />}
                        onSubmit={handleSubmitVisibility}
                    >
                        <RadioButtonGroupInput
                            source="visibility"
                            choices={[
                                { id: 'public', name: 'Public' },
                                { id: 'private', name: 'Private' }
                            ]}
                            row={false}
                            initialValue={record.visibility}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openVersion} onClose={() => setOpenVersion(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Version event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<VersionToolbar />}
                        onSubmit={handleSubmitVersion}
                    >
                        <RadioButtonGroupInput
                            source="version"
                            choices={[
                                { id: '3.0.0', name: '3.0.0' },
                                { id: '4.0.0', name: '4.0.0' },
                                { id: '4.1.0', name: '4.1.0' },
                            ]}
                            row={false}
                            initialValue={record.version}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openStreamLayout} onClose={() => setOpenStreamLayout(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Stream layout in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<StreamLayoutToolbar />}
                        onSubmit={handleSubmitStreamLayout}
                    >
                        <RadioButtonGroupInput
                            source="callParams.layoutParams.defaultLayout"
                            choices={[
                                { id: 'crop', name: 'Zoom in' },
                                { id: 'no-crop', name: 'Zoom out' },
                                { id: 'speaker', name: 'Ad+Active Speaker' },
                                { id: 'ad-participants', name: 'Ad+Participants' }
                            ]}
                            row={false}
                            initialValue={record.callParams && record.callParams.layoutParams && record.callParams.layoutParams.defaultLayout ? record.callParams.layoutParams.defaultLayout : 'no-crop'}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openVideoLabels} onClose={() => setOpenVideoLabels(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Participant name labels in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<VideoLabelsToolbar />}
                        onSubmit={handleSubmitVideoLabels}
                    >
                        <RadioButtonGroupInput
                            source="callParams.layoutParams.labels"
                            choices={[
                                { id: 'default', name: 'Default' },
                                { id: 'outlined', name: 'Outlined' }
                            ]}
                            row={false}
                            initialValue={record.callParams && record.callParams.layoutParams && record.callParams.layoutParams.labels ? record.callParams.layoutParams.labels : 'default'}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openStatus} onClose={() => setOpenStatus(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Status  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<StatusToolbar />}
                        onSubmit={handleSubmitStatus}
                    >
                        <RadioButtonGroupInput
                            source="status"
                            choices={[
                                { id: 'scheduled', name: 'Scheduled' },
                                { id: 'started', name: 'Started' },
                                { id: 'ended', name: 'Ended' }
                            ]}
                            row={false}
                            initialValue={record.status}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openLive} onClose={() => setOpenLive(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Live  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<LiveToolbar />}
                        onSubmit={handleSubmitLive}
                    >
                        <BooleanInput
                            source="live"
                            label="Live"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openArchiveEnabled} onClose={() => setOpenArchiveEnabled(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Archive enabled  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<ArchiveEnabledToolbar />}
                        onSubmit={handleSubmitArchiveEnabled}
                    >
                        <BooleanInput
                            source="archiveEnabled"
                            label="Archive Enabled"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openAcceptTips} onClose={() => setOpenAcceptTips(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Accept tips  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<AcceptTipsToolbar />}
                        onSubmit={handleSubmitAcceptTips}
                    >
                        <BooleanInput
                            source="acceptTips"
                            label="Accept Tips"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openType} onClose={() => setOpenType(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    The event type is:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<TypeToolbar />}
                        onSubmit={handleSubmitType}
                    >
                        <RadioButtonGroupInput
                            source="type"
                            choices={[
                                { id: 'event', name: 'event' },
                                { id: 'test-event', name: 'test-event' }
                            ]}
                            row={false}
                            initialValue={record.type}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openPopular} onClose={() => setOpenPopular(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Popular on the platform:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<PopularToolbar />}
                        onSubmit={handleSubmitPopular}
                    >
                        <BooleanInput
                            source="featured"
                            label="Popular"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Id
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField label="id" source="id" />{' '}
                        <CopyToClipboardButton source="id" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Event name
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="title" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience link
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {" "}
                        <FunctionField
                            label="Audience link"
                            render={(record) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={record.sharedLink}
                                >
                                    {record.sharedLink}
                                </a>
                            )}
                        />{" "}
                        <CopyToClipboardButton source="sharedLink" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Guest link
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {" "}
                        <FunctionField
                            label="Guest link"
                            render={(record) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={record.guestInvitationLink}
                                >
                                    {record.guestInvitationLink}
                                </a>
                            )}
                        />{" "}
                        <CopyToClipboardButton source="guestInvitationLink" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Event description
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <RichTextField label="" source="descriptionText" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Created
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <DateField
                            label="Created"
                            source="date"
                            showTime={true}
                            locales="en-US"
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Starts
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <DateField
                            label="Starts"
                            source="startDate"
                            showTime={true}
                            locales="en-US"
                        />
                    </TableCell>
                </TableRow>
                {!isB2B() ?
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Price
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <NumberField source="price" options={{ style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }} />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {!isB2B() ?
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Archive price
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <NumberField source="archivePrice" options={{ style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }} />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {!isB2B() ?
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Tips value
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <NumberField source="tipsValue" options={{ style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }} />
                        </TableCell>
                    </TableRow>
                    : null
                }
                <TableRow>
                    <TableCell component="th" scope="row">
                        Logo
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ImageField
                            source="logo"
                            title="logo"
                            sx={{
                                '& .RaImageField-image': {
                                    width: 100
                                }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Email
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <EmailField source="email" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Status
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Stack direction="horizontal" alignItems="center" spacing={5} >
                            <TextField source="status" />
                            <button
                                className="copy-to-clipboard-btn"
                                onClick={() => setOpenStatus(true)}
                            >
                                <EditIcon fontSize="inherit" />
                            </button>
                            {record.status === 'ended' || record.status === 'published' ?
                                <FunctionField
                                    label="More details"
                                    render={(record) => (
                                        <Link href={`/#/posts/${record.id}/show/`} className="launch-link" >
                                            <LaunchIcon fontSize='small' />
                                        </Link>
                                    )}
                                /> : null}
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Live
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="live" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenLive(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Popular on the platform
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="featured" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenPopular(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Hidden In
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {((!record.hiddenIn) || (record.hiddenIn && !record.hiddenIn.length)) ?
                            <span>not hidden</span> :
                            record.hiddenIn.map((item, index) => <span>{index ? ", " : ''}{item}</span>)}
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenHiddenIn(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Archive enabled
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="archiveEnabled" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenArchiveEnabled(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Accept tips
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="acceptTips" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenAcceptTips(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        User ID
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <div className="data-cell__copy-to-clipboard">
                            <FunctionField
                                label="User ID"
                                render={(record) => (
                                    <a
                                        href={`/#/users/${record.uid}/show/`}
                                    >
                                        {record.uid}
                                    </a>
                                )}
                            />
                            <CopyToClipboardButton source="uid" />
                        </div>

                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Visibility
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="visibility" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenVisibility(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Version
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="version" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenVersion(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Stream Layout
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <StreamLayoutField source="callParams.layoutParams.defaultLayout" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenStreamLayout(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Banner
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{
                            '&': {
                                display: 'flex'
                            }
                        }}
                    >
                        {(doc && doc.banner && doc.banner.img) ?
                            <div>
                                {doc.banner.link && doc.banner.img ?
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={doc.banner.link}
                                    >
                                        <ImageField
                                            source='img'
                                            record={doc.banner}
                                            sx={{
                                                '& .RaImageField-image': {
                                                    width: 300,
                                                    height: 'auto'
                                                }
                                            }}
                                        />
                                    </a>
                                    : doc.banner.img ?
                                        <ImageField
                                            source='img'
                                            record={doc.banner}
                                            sx={{
                                                '& .RaImageField-image': {
                                                    width: 300,
                                                    height: 'auto'
                                                }
                                            }}
                                        />
                                        : null
                                }
                            </div>
                            :
                            <span>none</span>
                        }
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenBanners(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Participant Name Labels
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            source="callParams.layoutParams.labels"
                            sx={{ "& ": { textTransform: "capitalize" } }}
                        />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenVideoLabels(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Hosts
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <EmailField source="email" />
                        <ArrayField source="cohosts">
                            <SingleFieldList
                                sx={{
                                    "& ": {
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        flexWrap: "wrap",
                                        marginTop: "0",
                                        marginBottom: "0",
                                    },
                                    "& .RaSingleFieldList-link:last-child": {
                                        marginBottom: "4px",
                                    },
                                }}
                            >
                                <EmailField source="text" />
                            </SingleFieldList>
                        </ArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Guest speakers
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ArrayField source="guestSpeakers">
                            <SingleFieldList
                                sx={{
                                    '& ': {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        flexWrap: 'wrap'
                                    },
                                    '& .RaSingleFieldList-link:first-child': {
                                        marginTop: '4px'
                                    }
                                }}
                            >
                                <EmailField source="email" />
                            </SingleFieldList>
                        </ArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Moderators
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ArrayField source="moderators">
                            <SingleFieldList
                                sx={{
                                    '& ': {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        flexWrap: 'wrap'
                                    },
                                    '& .RaSingleFieldList-link:first-child': {
                                        marginTop: '4px'
                                    }
                                }}
                            >
                                <EmailField source="email" />
                            </SingleFieldList>
                        </ArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Type
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Stack direction="horizontal" alignItems="center" spacing={5} >
                            <TextField source="type" />
                            <button
                                className="copy-to-clipboard-btn"
                                onClick={() => setOpenType(true)}
                            >
                                <EditIcon fontSize="inherit" />
                            </button>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Time zone
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="timeZone" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Description text
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="descriptionText" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Author pic
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                        <ImageField source="authorPic" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Body
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="body" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Author
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="author" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Category
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="cat" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience authenticate
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="audienceAuthenticate" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience Size
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <NumberField source="participants" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Estimate duration
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <FunctionField
                            source="estDuration"
                            render={record => `${record.estDuration ? record.estDuration : 0}min`}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Event duration
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <FunctionField
                            source="duration"
                            render={record => `${record.duration ? record.duration : 0}h`}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience overage quote
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="overage.audienceOverageMessage" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Duration overage quote
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="overage.durationOverageMessage" />
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    );
};

export const EventShow = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <Show actions={<InstanceShowActions />} title={<MainTitle />} {...props}>
            <ThemeProvider theme={theme}>
                <TabbedShowLayout>
                    <Tab label="general">
                        <GeneralInfoTable />
                    </Tab>
                    {!isB2B() ?
                        <Tab label="Tickets">
                            <TicketsFilteredList source={'id'} fieldName={'eventId'} />
                        </Tab>
                        : null
                    }
                    <Tab label="VMs">
                        <FanoutsFilteredList tab="events" />
                    </Tab>
                </TabbedShowLayout>
            </ThemeProvider>
        </Show>
    );
};