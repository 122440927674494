import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { convertMsToTime, hoursToMS } from "../../../utils/HelperFunctions";
import {
    ArrayInput,
    BooleanInput,
    Button,
    DateInput,
    Edit,
    ListButton,
    NumberInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    TopToolbar,
    useRecordContext,
    useRefresh,
} from "react-admin";
import { useForm } from "react-hook-form";
import { TabbedShowLayout, Tab } from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import moment from "moment";

const InstanceEditActions = ({ basePath, data, resource }) => {
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    // eslint-disable-next-line
    const [changeInput, setChangeInput] = useState(false);
    // eslint-disable-next-line
    const { watch, register } = useForm();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handlSubmit = (data) => {
        console.log("About to save extra hours", data);
        firebaseClient.saveExtraEventHours(data ? {
            user_id: data.user_id,
            subscription_id: data.id,
            extra_event_hours: data.extra_event_hours
        } : null).then(() => refresh());
        setOpen(false);
    };

    const UserEditToolbar = (props) => {
        return (
            <Toolbar
                {...props}
                sx={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                }}
            >
                <Stack direction="row" spacing={6}>
                    <SaveButton alwaysEnable={changeInput} />
                    <Button onClick={handleClose}>Cancel</Button>
                </Stack>
            </Toolbar>
        );
    };

    return (
        <TopToolbar>
            <ListButton basePath={basePath} />
            <Button variant="text" onClick={handleClickOpen}>
                Set extra hours
            </Button>
            {/*<ShowButton basePath={basePath} record={data} />*/}
            {/* Add your custom actions */}
            {/*<SuspendInstanceButton record={data} color="primary" >Suspend</SuspendInstanceButton>*/}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ textAlign: "center" }}>
                    Set extra event hours
                </DialogTitle>
                <DialogContent>
                    <SimpleForm
                        toolbar={<UserEditToolbar />}
                        sx={{
                            "& .MuiFormHelperText-root": {
                                display: "none",
                            },
                        }}
                        onSubmit={handlSubmit}
                    >
                        <NumberInput
                            source="extra_event_hours"
                            step={1}
                            resettable={true}
                            sx={{ width: "100%" }}
                            {...register("extra_event_hours")}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </TopToolbar>
    );
};

export const UserSubscriptionEdit = (props) => {
    const { watch, register } = useForm();
    const [changeInput, setChangeInput] = useState(false);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (type === "change") {
                setChangeInput(true);
            }
        });
    }, [watch, changeInput]);

    const UserEditToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton alwaysEnable={changeInput} />
            </Toolbar>
        );
    };

    const BalanceTable = ({ eventId }) => {
        const record = useRecordContext();

        console.log("TEST", record);

        return (
            <TableContainer
                sx={{
                    maxWidth: 450,

                    marginTop: "20px",
                    marginBottom: "20px",
                }}
                component={Paper}
            >
                <Table size="small">
                    <TableRow>
                        <TableCell
                            sx={{
                                width: 250,
                            }}
                            component="th"
                            scope="row"
                        >
                            Allocated free time
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {convertMsToTime((record.available_amount || 0))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Allocated extra time
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {convertMsToTime(hoursToMS(record.extra_event_hours) || 0)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Accumulated
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {convertMsToTime(record.accumulated_ms || 0)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Expires
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {record.valid_to_ts !== undefined
                                ? moment(
                                    (record.valid_to_ts)
                                ).format("L")
                                : null}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Available time
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {convertMsToTime(record.available_amount + (record.accumulated_ms || 0) + hoursToMS(record.extra_event_hours || 0))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Used time
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {convertMsToTime(record.used)}
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        );
    };

    const History = ({ eventId }) => {
        const record = useRecordContext();
        let collection = {};
        console.log("History rec", eventId, record);
        const [stats, setStats] = useState(null);
        const onStatusUpdate = (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    console.log("New item: ", change.doc.data());
                    collection[change.doc.id] = {
                        id: change.doc.id,
                        ...change.doc.data(),
                    };
                }
                if (change.type === "modified") {
                    console.log("Modified item: ", change.doc.data());
                    collection[change.doc.id] = {
                        id: change.doc.id,
                        ...change.doc.data(),
                    };
                }
                if (change.type === "removed") {
                    console.log("Removed item: ", change.doc.id);
                    delete collection[change.doc.id];
                }
            });
            let result =
                Object.values(collection).sort((a, b) => {
                    return a.when < b.when ? 1 : -1;
                }) || null;

            console.log("Got updated history: ", result);
            setStats(result);
        };

        useEffect(() => {
            const cb = firebaseClient.attachFSColListener(
                `/user_subscription/${record.id}/history`,
                onStatusUpdate
            );
            return () => {
                return firebaseClient.detachFSListener(cb);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return record && record.id && stats ? (
            <Timeline>
                {stats.map((data) => {
                    return (
                        <TimelineItem
                            sx={{
                                "& .MuiTimelineConnector-root": {
                                    height: "10px",
                                },
                            }}
                        >
                            <TimelineOppositeContent sx={{ m: "auto 0" }}>
                                <Typography variant="body2" component="span" sx={{
                                    width: "100px",
                                    display: "block",
                                    marginLeft: "auto"
                                }}>
                                    {data.when !== undefined
                                        ? moment(
                                            (data.when)
                                        ).format("MM/DD/YYYY  [\r\n]  HH:mm:ss")
                                        : null}
                                </Typography>

                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot
                                    color="primary"
                                    variant="outlined"
                                    title={data.what}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {data.what === "extra hours updated" ||
                                        data.what === "balance updated" ||
                                        data.what === "amount updated" ||
                                        data.what === "balance update" ? (
                                            <AddIcon />
                                        ) : data.what === "event bill" ? (
                                            <RemoveIcon />
                                        ) : data.what === "subscription update" ? (
                                            <icon ArrowForwardIcon />
                                        ) : (
                                                    <QuestionMarkIcon />
                                                )}
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{
                                    py: "22px",
                                    px: 2,
                                    "& .MuiTypography-body1": {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "start",
                                    },
                                }}
                            >
                                <Typography variant="h6" component="span">
                                    {data.what === "extra hours updated" ? (
                                        <Fragment>
                                            {"Extra time set: "}
                                            {data.extra_event_hours !== undefined
                                                ? (data.extra_event_hours ?
                                                    convertMsToTime(hoursToMS(data.extra_event_hours))
                                                    : '00:00:00')
                                                : null}
                                        </Fragment>
                                    ) : data.what === "event bill" ? (
                                        <Fragment>
                                            <a
                                                href={`/#/events/${data.eventId || data.id}/show/1`}
                                            >
                                                Event
                                            </a>{" "}
                                            {`billed: `}
                                            {data.duration_15min !== undefined
                                                ? convertMsToTime(data.duration_15min)
                                                : null}
                                        </Fragment>
                                    ) : data.what === "subscription update" ? (
                                        <Fragment>
                                            {"Subscription updated "}
                                        </Fragment>
                                    ) : data.what === "balance update" ||
                                        data.what === "balance updated" ||
                                        data.what === "amount updated" ? (
                                                        <Fragment>
                                                            {"Balance updated: "}
                                                            {data.available_amount !== undefined
                                                                ? convertMsToTime(data.available_amount)
                                                                : null}
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>{"[Unknown]"}</Fragment>
                                                    )}
                                </Typography>
                                <Typography variant="body2">
                                    {typeof data.who === "object"
                                        ? data.who.name || "[auto]"
                                        : data.who || "[auto]"}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
            </Timeline>
        ) : (
                <div>No history for this subscription</div>
            );
    };

    return (
        <Edit
            sx={{ width: "100%" }}
            actions={<InstanceEditActions />}
            {...props}
        >
            <TabbedShowLayout>
                <Tab label="Balance">
                    <BalanceTable />
                    <History />
                </Tab>
                <Tab label="Details">
                    <SimpleForm toolbar={<UserEditToolbar />}>
                        <Typography variant="h6" gutterBottom>
                            Status
                        </Typography>
                        <Stack direction="column">
                            <Stack direction="row" spacing={2}>
                                <SelectInput
                                    required={false}
                                    allowEmpty={true}
                                    emptyValue={""}
                                    source="status"
                                    choices={[
                                        {
                                            id: "requested",
                                            name: "User requested",
                                            disabled: true,
                                        },
                                        { id: "used", name: "Used" },
                                        {
                                            id: "pending",
                                            name: "Pending",
                                            disabled: true,
                                        },
                                        { id: "active", name: "Active" },
                                        { id: "suspended", name: "Suspended" },
                                        { id: "overdue", name: "Overdue" },
                                    ]}
                                />
                            </Stack>
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            User
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{ width: "100%" }}
                            spacing={2}
                        >
                            <TextInput
                                source="user_id"
                                disabled={true}
                                label={"User ID"}
                                sx={{ width: "25%" }}
                            />
                            <TextInput
                                source="user_name"
                                disabled={false}
                                label={"Name"}
                                sx={{ width: "75%" }}
                                {...register("user_name")}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            sx={{ width: "100%" }}
                            spacing={2}
                        >
                            <TextInput
                                source="companyName"
                                disabled={false}
                                defaultValue=""
                                label={"Company name"}
                                sx={{ width: "25%" }}
                                {...register("companyName")}
                            />
                            <TextInput
                                source="phoneNumber"
                                disabled={false}
                                defaultValue=""
                                label={"Phone number"}
                                sx={{ width: "25%" }}
                                {...register("phoneNumber")}
                            />
                            <TextInput
                                source="userTitle"
                                disabled={false}
                                defaultValue=""
                                label={"Title"}
                                sx={{ width: "25%" }}
                                {...register("userTitle")}
                            />
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            Subscription
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{ width: "100%" }}
                            spacing={2}
                        >
                            <TextInput
                                source="subscription_id"
                                disabled={true}
                                label={"Subscrption ID"}
                                sx={{ width: "25%" }}
                                {...register("subscription_id")}
                            />
                            <TextInput
                                source="subscription_name"
                                sx={{ minWidth: 250, width: "75%" }}
                                {...register("subscription_name")}
                            />
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            Date valid
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ width: "100%" }}
                        >
                            <DateInput
                                label={"From"}
                                source="valid_from"
                                sx={{ minWidth: 250, width: "50%" }}
                                isRequired={true}
                                validate={[required()]}
                                {...register("valid_from")}
                            />
                            <DateInput
                                label={"To"}
                                source="valid_to"
                                sx={{ minWidth: 250, width: "50%" }}
                                isRequired={true}
                                validate={[required()]}
                                {...register("valid_to")}
                            />
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            Billing
                        </Typography>
                        <Stack
                            direction="column"
                            sx={{ width: "100%", marginTop: "0" }}
                        >
                            <Stack direction="row" spacing={2}>
                                <NumberInput
                                    source="payment_amount"
                                    step={0.01}
                                    resettable={true}
                                    {...register("payment_amount")}
                                />
                                <SelectInput
                                    allowEmpty={true}
                                    source="payment_currency"
                                    choices={[{ id: "usd", name: "USD" }]}
                                    label={"Currency"}
                                    defaultValue={"usd"}
                                    emptyValue={""}
                                    {...register("payment_currency")}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <SelectInput
                                    allowEmpty={true}
                                    emptyValue={""}
                                    source="payment"
                                    label="Payment method"
                                    autoWidth={true}
                                    choices={[
                                        {
                                            id: "credit_card ",
                                            name: "Overages paid by credit card ",
                                        },
                                        {
                                            id: "credit_card_or_invoice",
                                            name: "Credit card or invoice",
                                        },
                                        {
                                            id: "monthly_or_annual_invoice",
                                            name: "Monthly or Annual Invoice",
                                        },
                                    ]}
                                    sx={{ width: "25%" }}
                                    {...register("payment")}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ width: "100%" }}
                                >
                                    <DateInput
                                        label={"Last payment date"}
                                        source="last_payment_date"
                                        defaultValue={null}
                                        sx={{ minWidth: 250, width: "50%" }}
                                        {...register("last_payment_date")}
                                    />
                                    <DateInput
                                        label={"Next payment due date"}
                                        source="next_payment_due_date"
                                        defaultValue={null}
                                        sx={{ minWidth: 250, width: "50%" }}
                                        {...register("next_payment_due_date")}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            Account
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ width: "100%" }}
                        >
                            <BooleanInput
                                source="sso"
                                label={"Single sign-on"}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ width: "100%" }}
                        >
                            <NumberInput
                                source="concurrent_events"
                                step={1}
                                resettable={true}
                                {...register("concurrent_events")}
                            />
                            <SelectInput
                                source="duration_overage"
                                required={true}
                                allowEmpty={true}
                                autoWidth={true}
                                displayEmpty={false}
                                emptyValue={""}
                                choices={[
                                    {
                                        id: "duration_overage_1",
                                        name: "Subtracted from quarterly allowance or $100 if allowance used",
                                    },
                                    {
                                        id: "duration_overage_2",
                                        name: "Subtracted from annual allowance",
                                    },
                                    {
                                        id: "duration_overage_3",
                                        name: "Unlimited",
                                    },
                                ]}
                                sx={{ width: "25%" }}
                                {...register("duration_overage")}
                            />
                        </Stack>
                        <Stack direction="column" sx={{ width: "100%" }}>
                            <Stack
                                direction="row"
                                sx={{ width: "75%" }}
                                spacing={2}
                            >
                                <NumberInput
                                    source="max_event_hours"
                                    step={1}
                                    resettable={true}
                                    sx={{ width: "33%" }}
                                    {...register("max_event_hours")}
                                />
                                <SelectInput
                                    source="max_event_hours_period"
                                    required={false}
                                    allowEmpty={true}
                                    emptyValue={""}
                                    autoWidth={true}
                                    choices={[
                                        { id: "quarter", name: "Quarter" },
                                        { id: "year", name: "Year" },
                                    ]}
                                    sx={{ width: "34%" }}
                                    {...register("max_event_hours_period")}
                                />
                                <NumberInput
                                    source="extra_event_hours"
                                    step={1}
                                    disabled={true}
                                    resettable={true}
                                    sx={{ width: "33%" }}
                                    {...register("extra_event_hours")}
                                />
                            </Stack>
                            {/* <Stack
                                direction="column"
                                spacing={2}
                                sx={{ width: "50%" }}
                                style={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    border: "solid 1px gray",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ width: "100%" }}
                                >
                                    Balance:&nbsp;
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ width: "100%" }}
                                >
                                    Allocated free time:&nbsp;
                                    <FunctionField
                                        source="available_amount"
                                        label="Allocated free time"
                                        render={(record) =>
                                            msToHhMm(record.available_amount)
                                        }
                                        //sx={{ width: "25%" }}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ width: "100%" }}
                                >
                                    Allocated extra time:&nbsp;
                                    <FunctionField
                                        source="extra_event_hours"
                                        label="Allocated extra time"
                                        render={(record) =>
                                            msToHhMm(
                                                parseInt(
                                                    record.extra_event_hours
                                                )
                                                    ? parseInt(
                                                          record.extra_event_hours
                                                      ) *
                                                          60 *
                                                          60 *
                                                          1000
                                                    : 0
                                            )
                                        }
                                        //sx={{ width: "25%" }}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ width: "100%" }}
                                >
                                    Used time:&nbsp;
                                    <FunctionField
                                        source="used"
                                        label="Used time"
                                        //sx={{ width: "25%" }}
                                        render={(record) =>
                                            msToHhMm(record.used)
                                        }
                                    />
                                </Stack>
                            </Stack> */}
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            Event
                        </Typography>
                        <Stack direction="column" xs={{ width: "50%" }}>
                            <Stack
                                direction="row"
                                sx={{ width: "100%" }}
                                spacing={2}
                            >
                                <SelectInput
                                    allowEmpty={true}
                                    emptyValue={""}
                                    source="event_brending"
                                    choices={[
                                        { id: "logo", name: "Corporate logo" },
                                        {
                                            id: "event_card_lobby",
                                            name: "Branding in Event Card and Audience Lobby",
                                        },
                                    ]}
                                    sx={{ width: "50%", marginTop: "0" }}
                                    {...register("event_brending")}
                                />
                                <SelectInput
                                    source="pre_event_rehearsal_min"
                                    defaultValue={30}
                                    emptyText={"Please select some value"}
                                    allowEmpty={true}
                                    emptyValue={""}
                                    required={true}
                                    autoWidth={true}
                                    choices={[
                                        { id: "30", name: "30 minutes" },
                                        { id: "-1", name: "Unlimited" },
                                    ]}
                                    sx={{ width: "25%" }}
                                    {...register("pre_event_rehearsal_min")}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                sx={{ width: "100%" }}
                                spacing={2}
                            >
                                {/*<BooleanInput source="pre_event_analytics" />*/}
                                <BooleanInput source="event_analytics" />
                                {/*<BooleanInput source="post_event_analytics" />*/}
                            </Stack>
                            <Stack
                                direction="row"
                                sx={{ width: "100%" }}
                                spacing={2}
                            >
                                <BooleanInput source="live_chat" />
                                <BooleanInput source="screenshare" />
                                <BooleanInput source="event_recording" />
                                <BooleanInput source="knocks" />
                            </Stack>
                            <SelectInput
                                source="audience_size"
                                required={false}
                                defaultValue={500}
                                autoWidth={true}
                                allowEmpty={true}
                                emptyValue={""}
                                choices={[
                                    { id: "500", name: "< 500" },
                                    { id: "1000", name: "< 1000" },
                                    { id: "2000", name: "< 2000" },
                                    { id: "3000", name: "< 3000" },
                                    { id: "5000", name: "< 5000" },
                                    { id: "10000", name: "< 10000" },
                                    { id: "25000", name: "< 25000" },
                                    { id: "50000", name: "< 50000" },
                                    { id: "", name: "On request" },
                                ]}
                                {...register("audience_size")}
                            />
                            <Box sx={{ width: "50%" }}>
                                <ArrayInput
                                    label="Hosts"
                                    className="c-form-control"
                                    source="hosts"
                                    defaultValue={[]}
                                >
                                    <SimpleFormIterator
                                        addButton={
                                            <Button label={"Add host"}>
                                                <AddCircleIcon />
                                            </Button>
                                        }
                                        removeButton={
                                            <Button title={"Remove host"}>
                                                <RemoveCircleIcon />
                                            </Button>
                                        }
                                        disableReordering
                                        disableRowNumber
                                        className="c-form-iterator"
                                    >
                                        <TextInput
                                            source="mail"
                                            sx={{ width: "100%" }}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                            <NumberInput
                                sx={{ width: "25%" }}
                                source="number_of_hosts"
                                label="Max number of hosts"
                                {...register("number_of_hosts")}
                            />
                        </Stack>
                        <Typography variant="h6" gutterBottom>
                            Prices
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ width: "100%" }}
                        >
                            <NumberInput
                                source="attendee_overage_usd"
                                step={0.01}
                                resettable={true}
                                sx={{ width: "25%" }}
                                {...register("attendee_overage_usd")}
                            />
                            <NumberInput
                                source="duration_overage_usd"
                                step={0.01}
                                resettable={true}
                                sx={{ width: "25%" }}
                                {...register("duration_overage_usd")}
                            />
                        </Stack>
                    </SimpleForm>
                </Tab>
            </TabbedShowLayout>
        </Edit>
    );
};
