import * as React from "react";
import { useEffect, useState } from "react";
import {
    Show,
    SimpleShowLayout,
    useRecordContext,
} from "react-admin";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import { Link } from '@material-ui/core';

const Stats = ({ eventId }) => {
    const record = useRecordContext();
    // console.log(record);
    // eslint-disable-next-line
    const [stats, setStats] = useState(null);

    useEffect(() => {
    }, []);

    return record && record.id && stats ? (
        <TableContainer
            sx={{
                padding: "30px",
            }}
            component={Paper}
        >
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        ID
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <div className="data-cell__copy-to-clipboard">
                            <span>{record.id}</span>
                            <CopyToClipboardButton string source={record.id} className="launch-link" />
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Event Id
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <div className="data-cell__copy-to-clipboard">
                            <Link href={`/#/${record.status === 'scheduled' ? 'events' : 'posts'}/${record.eventId}/show`} className="launch-link" >
                                {record.eventId}
                            </Link>
                            <CopyToClipboardButton string source={record.eventId} className="launch-link" />
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Status
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.state}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Services
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.services}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Compositing
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.compositing}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Max idle
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.maxIdleCpus}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Total conference
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.totalConferences}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Total idle
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.totalIdleCpu}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Total viewers
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.totalViewers}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Updated
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {stats.data.timestamp !== undefined
                            ? moment(
                                (stats.data.timestamp)
                            ).format("MM/DD/YYYY HH:mm:ss")
                            : null}
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    ) : (
            <div>No data for this defaults</div>
        );
};

export const DefaultsShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <Stats />
            </SimpleShowLayout>
        </Show>
    );
};