import * as React from "react";
import { useEffect, useState } from "react";
import {
    useRecordContext,
    useRedirect
} from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import moment from "moment/moment";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from '@material-ui/core';
import CopyToClipboardButton from "../../common/CopyToClippboardButton";

// eslint-disable-next-line
export default ({ source, fieldName, tab }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    // console.log(record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        // console.log('onDocUpdate', doc);
        // console.log("Got FS data: ", doc.data());
        if (doc && doc.data) {
            let filtered = doc.data.map((row) => {
                const updatedRow = {
                    id: row.id,
                    state: row.state,
                    services: row.services,
                    eventId: row.eventId,
                    maxIdleCpus: row.data.maxIdleCpus,
                    totalViewers: row.data.totalViewers,
                    compositing: row.data.compositing,
                    timestamp: row.data.timestamp,
                    totalConferences: row.data.totalConferences,
                    totalIdleCpu: row.data.totalIdleCpu,
                    eventName: row.event && row.event.eventName ? row.event.eventName : '',
                    startDate: row.event && row.event.startDate ? row.event.startDate : '',
                };

                if (updatedRow.startDate) {
                    const m = moment(updatedRow.startDate);
                    updatedRow.startDate = m.format("L") + ' ' + m.format("LTS");
                }
                return updatedRow;
            });
            console.log('filtered', filtered);
            setDoc(filtered || null);
        } else {
            setDoc(null);
        }
        setLoaded(true);
    };

    useEffect(() => {
        firebaseClient
            .getFanouts({ eventId: record.id })
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            });
        return () => { };
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            minWidth: 150,
            maxWidth: 200,
            flex: 1,
            renderCell: (params) => <div className="data-cell__copy-to-clipboard"><span>{params.id}</span><CopyToClipboardButton string source={params.id} className="launch-link" /></div>
        },
        {
            field: 'eventId',
            headerName: 'Event Id',
            minWidth: 250,
            maxWidth: 300,
            flex: 1,
            renderCell: (params) => <div className="data-cell__copy-to-clipboard">
                <Link href={`/#/${tab || 'posts'}/${params.row.eventId}/show`} className="launch-link" >
                    {params.row.eventId}
                </Link>
                <CopyToClipboardButton string source={params.row.eventId} className="launch-link" />
            </div>
        },
        // {
        //     field: 'eventName',
        //     headerName: 'Event name',
        //     minWidth: 200,
        //     maxWidth: 300,
        //     flex: 1,
        // },
        // {
        //     field: 'startDate',
        //     headerName: 'Start date',
        //     minWidth: 200,
        //     maxWidth: 300,
        //     flex: 1,
        // },
        {
            field: 'state',
            headerName: 'State',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        },
        {
            field: 'services',
            headerName: 'Services',
            minWidth: 150,
            maxWidth: 250,
            flex: 1,
        },
        {
            field: 'maxIdleCpus',
            headerName: 'Max Idle',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        },
        {
            field: 'totalViewers',
            headerName: 'Total viewers',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
        },
    ];

    return record && record.id && doc ? (
        <div style={{ display: 'flex', height: '100%', minHeight: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {!doc || !doc.length ? (
                    'No VMs found'
                ) : (
                        <React.Fragment>
                            <DataGrid
                                pagination={false}
                                disableColumnMenu={true}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    border: 0,
                                    '& .MuiDataGrid-columnSeparator--sideRight': {
                                        display: 'none'
                                    },
                                    '& .MuiDataGrid-columnHeaders + div': {
                                        height: 'auto !important',
                                        minHeight: '100px'
                                    },
                                    '& .MuiDataGrid-virtualScroller': {
                                        height: 'auto !important'
                                    }
                                }}
                                getRowId={(row) => row.id}
                                rows={doc}
                                columns={columns}
                                pageSize={100}
                                withBorder={false}
                                hideFooter
                                // initialState={{
                                //     sorting: {
                                //         sortModel: [{ field: 'date', sort: 'DESC' }]
                                //     }
                                // }}
                                onRowClick={({ id }) => { redirect(`/fanouts/${id}/show`) }}
                            />
                        </React.Fragment>
                    )}
            </div>
        </div>
    ) : (
            <div>
                {error
                    ? error
                    : !loaded
                        ? "Loading..."
                        : "No VMs for filter"}
            </div>
        );
};
