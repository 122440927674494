import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
    BooleanInput,
    Create,
    ListButton,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar
} from "react-admin";
import { useForm } from 'react-hook-form';

const CreateActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        {/*<ShowButton basePath={basePath} record={data} />*/}
        {/* Add your custom actions */}
        {/*<SuspendInstanceButton record={data} color="primary" >Suspend</SuspendInstanceButton>*/}
    </TopToolbar>
);

export const TenantCreate = (props) => {

    const { watch, register } = useForm();
    const [changeInput, setChangeInput] = useState(false)

    useEffect(() => {
        watch((value, { name, type }) => {
            if (type === 'change') {
                setChangeInput(true)
            }
            if (type === 'click') {
                setChangeInput(true)
            }
        }
        )
    }, [watch, changeInput]);

    const UserEditToolbar = (props) => {

        return (
            <Toolbar {...props}>
                <SaveButton disabled={!changeInput} />
            </Toolbar>
        )
    }

    return (
        <Create sx={{ width: "100%" }} actions={<CreateActions />} {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <Typography variant="h6" gutterBottom>
                    Tenant
                </Typography>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2}>
                        {/*<TextInput source="id" disabled={true}/>*/}
                    </Stack>
                    <Stack direction="row" spacing={2} >
                        <TextInput source="name" sx={{ minWidth: 250, width: "50%" }}  {...register("name")} />
                        <BooleanInput source="active" />
                    </Stack>
                </Stack>
            </SimpleForm>
        </Create>
    )
} 
