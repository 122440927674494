import * as React from "react";
import {
    Show,
    TextField,
    TopToolbar,
    ListButton,
    useRecordContext,
    Tab,
    TabbedShowLayout,
} from "react-admin";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import { Link } from "@material-ui/core";
import TimestampDateTimeField from "../../common/TimestampDateTimeField";
import { getTicketStore } from '../../../utils/HelperFunctions';

const UserShowActions = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} />
        </TopToolbar>
    );
};

const AuthorField = () => {
    const record = useRecordContext();
    const { author, authorId } = record;
    return record ?
        <Link href={`/#/users/${authorId}/show/`} className="launch-link" >
            {author || authorId}
        </Link> : null;
}
AuthorField.defaultProps = { label: 'Author' };

const UserField = () => {
    const record = useRecordContext();
    const { user, userId } = record;
    return record ?
        <Link href={`/#/users/${userId}/show/`} className="launch-link" >
            {user || userId}
        </Link> : null;
}
UserField.defaultProps = { label: 'User' };

const AmountField = () => {
    const record = useRecordContext();
    const { price } = record;
    return price ? `$${price}` : "Free";
}
AmountField.defaultProps = { label: 'Amount' };

const StoreField = () => {
    const record = useRecordContext();
    const { id } = record;
    return id ? getTicketStore(id) : "N/A";
}
StoreField.defaultProps = { label: 'Store' };

const ShowField = () => {
    const record = useRecordContext();
    const { title, event, eventId } = record;
    return record ?
        <Link href={`/#/posts/${eventId}/show/`} className="launch-link" >
            {title || event || eventId}
        </Link> : null;
}
ShowField.defaultProps = { label: 'Show' };
export const TicketsShow = (props) => {
    useEffect(() => {
        // console.log("Scrolling...", props);
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return <Show actions={<UserShowActions />} {...props}>
        <>
            <TabbedShowLayout>
                <Tab label="Ticket">
                    <TableContainer
                        sx={{
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                        component={Paper}
                    >
                        <Table size="small">
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        ID
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                <div className="data-cell__copy-to-clipboard">
                                    <TextField source="id" />{" "}
                                    <CopyToClipboardButton source="id" />
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Author name
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <AuthorField />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Show
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <ShowField />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        User
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <UserField />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Role
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <TextField source="role" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Amount
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <AmountField />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Store
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <StoreField />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Status
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <TextField source="status" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Payment date
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <TimestampDateTimeField source="paymentDate" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Payment Status
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <TextField source="paymentStatus" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Payment ID
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <TextField source="paymentId" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: 300 }}
                                >
                                    <Typography component="span" variant="body2">
                                        Charge ID
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <TextField source="chargeId" />
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </Tab>
            </TabbedShowLayout>
        </>
    </Show>
};