import * as React from "react";
import { useEffect } from "react";
import {
    Button,
    Datagrid,
    List,
    TextField,
    Toolbar,
    useListContext,
    useRecordContext,
    useRefresh,
} from "react-admin";
import { Link } from "@material-ui/core";
import TimestampDateTimeField from './../../common/TimestampDateTimeField'
import { getTicketStore } from '../../../utils/HelperFunctions';
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
export const TipsList = () => {
    const refresh = useRefresh();
    const refreshInterval = 60 * 1000; // 30 seconds

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refresh, refreshInterval]);

    const AuthorField = () => {
        const record = useRecordContext();
        const { author, authorId } = record;
        return record ?
            <Link href={`/#/users/${authorId}/show/`} className="launch-link" >
                {author || authorId}
            </Link> : null;
    }
    AuthorField.defaultProps = { label: 'Author' };

    const UserField = () => {
        const record = useRecordContext();
        const { user, userId } = record;
        return record ?
            <Link href={`/#/users/${userId}/show/`} className="launch-link" >
                {user || userId}
            </Link> : null;
    }
    UserField.defaultProps = { label: 'User' };
    const ShowField = () => {
        const record = useRecordContext();
        const { title, event, eventId } = record;
        return record ?
            <Link href={`/#/posts/${eventId}/show/`} className="launch-link" >
                {title || event || eventId}
            </Link> : null;
    }
    ShowField.defaultProps = { label: 'Show' };
    const AmountField = () => {
        const record = useRecordContext();
        const { price } = record;
        return price ? `$${price}` : "";
    }
    AuthorField.defaultProps = { label: 'Amount' };
    const StatusField = () => {
        const record = useRecordContext();
        const { paymentStatus, status } = record;
        return paymentStatus || status;
    }
    StatusField.defaultProps = { label: 'Status' };
    const StoreField = () => {
        const record = useRecordContext();
        const { id } = record;
        return id ? getTicketStore(id) : "N/A";
    }
    StoreField.defaultProps = { label: 'Store' };

    const Paginatior = (params) => {
        const { data, isLoading, filterValues, setFilters } = useListContext();
        let { nav_at } = filterValues;
        const thefirst = data && data.length ? data[0].paymentDate : 0;
        const thelast = data && data.length ? data[data.length - 1].paymentDate : 0;
        //console.log('PostPagination params', thefirst, thelast, nav_at,  data);

        return <Toolbar>
            <Button
                key="home"
                disabled={isLoading}
                onClick={(event) => {
                    //console.log('Home clicked', data, thelast);
                    setFilters({
                        ...filterValues,
                        nav_direction: 'next',
                        nav_at: 0,
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }}>
                <KeyboardDoubleArrowLeftIcon />  Home
            </Button>
            <Button
                key="previous"
                disabled={isLoading || !nav_at /*|| (thefirst==nav_at)*/}
                onClick={(event) => {
                    //console.log('Prev clicked', data, thefirst);
                    setFilters({
                        ...filterValues,
                        nav_direction: 'prev',
                        nav_at: thefirst,
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                <ChevronLeft /> Previous
            </Button>
            <Button
                key="next"
                disabled={isLoading /*|| nav_at && (thelast==nav_at)*/}
                onClick={(event) => {
                    //console.log('Next clicked', data, thelast, filterValues);
                    setFilters({
                        ...filterValues,
                        nav_direction: 'next',
                        nav_at: thelast,
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }}>
                Next  <ChevronRight />
            </Button>
        </Toolbar>
    }

    return (
        <List
            filters={[]}
            pagination={<Paginatior />}
            perPage={50}
            filterDefaultValues={{ nav_direction: 'next', nav_at: 0 }}
            sort={{ field: "paymentDate", order: "DESC" }}
            actions={null}
        >
            <Datagrid optimized rowClick="show" bulkActionButtons={false}>
                <TimestampDateTimeField source="paymentDate" label={"Date"} sortable={false} />
                <TextField sortable={false} source={'id'} />
                <ShowField sortable={false} />
                <AuthorField label={"Author"} sortable={false} />
                <UserField sortable={false} />
                <AmountField label={"Amount"} sortable={false} />
                <StoreField label={"Store"} sortable={false} />
                <StatusField label={"Status"} sortable={false} />
            </Datagrid>
        </List>
    );
};
