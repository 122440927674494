import { configureStore } from "@reduxjs/toolkit";
// import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers/Reducer';
import appClient from '../utils/AppClient';
import firebaseClient from '../utils/FirebaseClient';

// export const history = createBrowserHistory();

// Creating store
let middleware = [
    // routerMiddleware(history), // for dispatching history actions
    thunk,
    // appClient.createMiddleware(),
    // firebaseClient.createMiddleware()
];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
}

export const store = configureStore({
    reducer: createRootReducer(),
    middleware: middleware,
    devTools: true
});

// Set store
appClient.setStore(store);
firebaseClient.setStore(store);