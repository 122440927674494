import { FunctionField } from "react-admin";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as React from "react";

const CopyToClipboardButton = ({ source, string}) => {
    let deep_value = (obj, dspath) => {
        console.log('TEST', source, "OBJECT", obj, "DSPATH", dspath)
        if(string){
            return source
        }else {
            //console.log('About to get deep path for', obj, dspath, dspath.split('.'));
            for (let i = 0, path = dspath.split('.'), len = path.length; i < len; i++) {
                obj = obj[path[i]];
            };
            return obj;     
        }
    };

    return (
        <FunctionField
            label="Copy to clipboard"
            render={(record) => (
                <button
                    className="copy-to-clipboard-btn"
                   
                    onClick={(event) => 
                       { event.stopPropagation()       
                         navigator.clipboard.writeText(deep_value(record, source))  
                         }}
                >
                    <ContentCopyIcon fontSize="inherit" /> 
                </button>
            )}
        />
    );
}

export default CopyToClipboardButton;