import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { appTitle, appLogo, domain } from './utils/HelperFunctions';
import firebaseClient from './utils/FirebaseClient';
import gapiClient from './utils/GapiClient';
import { Helmet, HelmetProvider } from "react-helmet-async";

const renderApp = (dataProvider) => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <HelmetProvider>
                    <Helmet>
                        {/*Primary Meta Tags*/}
                        <meta charSet="utf-8" />
                        <title>{appTitle}</title>
                        <link rel="icon" href={appLogo} />
                        <link rel="apple-touch-icon" href={appLogo} />
                        <link rel="android-touch-icon" href={appLogo} />
                        <link rel="windows-touch-icon" href={appLogo} />
                        <meta name="title" content={appTitle} />
                        <meta name="description" content={appTitle} />
                        {/*Open Graph / Facebook*/}
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={domain} />
                        <meta property="og:title" content={appTitle} />
                        <meta property="og:description" content={appTitle} />
                        <meta property="og:image" content={appLogo} />
                        {/*Twitter*/}
                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content={domain} />
                        <meta property="twitter:title" content={appTitle} />
                        <meta property="twitter:description" content={appTitle} />
                        <meta property="twitter:image" content={appLogo} />
                    </Helmet>
                    <App dataProvider={dataProvider} />
                </HelmetProvider>
            </Provider>
        </React.StrictMode>
    );
}

const init = () => {
    firebaseClient.initFirebase().then(gapiClient.initGapi).then((res) => {
        const dataProvider = gapiClient.getDataProvider();
        console.log('Firebase  initialized', res, dataProvider)
        if (res) {
            firebaseClient.init().then((res) => {
                console.log('firebase client initialized')
                renderApp(dataProvider);
            })
        }
    });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// eslint-disable-next-line
const error = (message) => {

    ReactDOM.render(
        <div>{message || 'Access not granted'}</div>,
        document.getElementById('root')
    );
}

let passwordProtected = false;

if (!passwordProtected) {
    console.log('About to initialize app');
    init();
} else {
    console.log('About to verify user credentials');
    const Http = new XMLHttpRequest();
    Http.withCredentials = true;
    let apiurl = "https://us-central1-streemz-net.cloudfunctions.net";
    const url = `${apiurl}/auth`;

    Http.onreadystatechange = (e) => {
        console.log('onreadystatechange', e, Http.responseText, Http.status);
        if (Http.readyState === 4) {
            console.log('response', Http.response, Http.getAllResponseHeaders());
            if (Http.status === 200)
                init();
            else
                error();
        }
    }

    Http.onerror = (e) => {
        console.log('onreadystatechange', e, Http.responseText, Http.status);
        // if (Http.readyState === 4) {
        console.log('response', Http.response, Http.getAllResponseHeaders());
        error('Could not verify user credentials');
        // }
    }
    Http.open("GET", url, true);
    Http.send();
}
