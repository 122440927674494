// import ChevronLeft from '@mui/icons-material/ChevronLeft';
// import ChevronRight from '@mui/icons-material/ChevronRight';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CopyToClipboardButton from '../../common/CopyToClippboardButton';
// import { Chip } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import {
    BooleanField,
    // Button,
    Datagrid,
    List,
    TextField,
    SearchInput,
    // Toolbar,
    // useListContext,
    useRefresh,
    useRecordContext,
} from 'react-admin';
import { isB2B } from '../../../utils/HelperFunctions';


const IdField = () => {
    const record = useRecordContext();
    return record && record.id ?
        <div className="data-cell__copy-to-clipboard"><span>{record.id}</span><CopyToClipboardButton string source={record.id} className="launch-link" /></div> : null;
}
IdField.defaultProps = { label: 'Id' };

export const UserList = (props) => {
    const refresh = useRefresh();
    const refreshInterval = 12 * 60 * 60 * 1000; // 30 seconds

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refresh, refreshInterval]);

    // const QuickFilter = ({ label }) => {
    //     return <Chip sx={{ marginBottom: 1 }} label={label} />;
    // };


    // const Paginatior = (params) => {
    //     const { data, isLoading, filterValues, setFilters } = useListContext();
    //     let { nav_at } = filterValues;
    //     const thefirst = data && data.length ? data[0].username : 0;
    //     const thelast = data && data.length ? data[data.length - 1].username : 0;
    //     //console.log('PostPagination params', thefirst, thelast, nav_at,  data);

    //     return <Toolbar>
    //         <Button
    //             key="home"
    //             disabled={isLoading}
    //             onClick={(event) => {
    //                 //console.log('Home clicked', data, thelast);
    //                 setFilters({
    //                     ...filterValues,
    //                     nav_direction: 'next',
    //                     nav_at: 0,
    //                 });
    //                 event.preventDefault();
    //                 event.stopPropagation();
    //             }}>
    //             <KeyboardDoubleArrowLeftIcon />  Home
    //         </Button>
    //         <Button
    //             key="previous"
    //             disabled={isLoading || !nav_at /*|| (thefirst==nav_at)*/}
    //             onClick={(event) => {
    //                 //console.log('Prev clicked', data, thefirst);
    //                 setFilters({
    //                     ...filterValues,
    //                     nav_direction: 'prev',
    //                     nav_at: thefirst,
    //                 });
    //                 event.preventDefault();
    //                 event.stopPropagation();
    //             }}
    //         >
    //             <ChevronLeft /> Previous
    //         </Button>
    //         <Button
    //             key="next"
    //             disabled={isLoading /*|| nav_at && (thelast==nav_at)*/}
    //             onClick={(event) => {
    //                 //console.log('Next clicked', data, thelast, filterValues);
    //                 setFilters({
    //                     ...filterValues,
    //                     nav_direction: 'next',
    //                     nav_at: thelast,
    //                 });
    //                 event.preventDefault();
    //                 event.stopPropagation();
    //             }}>
    //             Next  <ChevronRight />
    //         </Button>
    //     </Toolbar>
    // }

    const listFilters = [
        <SearchInput
            source="search_by"
            alwaysOn
            fullWidth
            sx={{
                '& ': {
                    width: '360px'
                }
            }}
            placeholder='Search user'
        />,
        // <QuickFilter
        //     source="administrator"
        //     label="Creator"
        //     defaultValue={true}
        // />,
    ];

    const CustomEmpty = () => <p className='empty-text'>No searched users</p>;

    return (
        <List
            {...props}
            filters={listFilters}
            perPage={50}
            sort={{ field: "username", order: "ASC" }}
            pagination={false}
            filterDefaultValues={{ nav_direction: 'next', nav_at: 0, }}
            actions={null}
        >
            <Datagrid
                optimized
                rowClick="show"
                bulkActionButtons={false}
                empty={<CustomEmpty />}
            >
                <TextField source="username" sortable={false} />
                <IdField source="id" sortable={false} />
                <TextField source="email" sortable={false} />
                {/* <TextField source="first_name" sortable={false} />
                <TextField source="last_name" sortable={false} /> */}
                <BooleanField source={isB2B() ? 'eventAdmin' : 'broadcaster'} label={"Creator"} sortable={false} />
                <BooleanField source="administrator" label={"Administrator"} sortable={false} />
            </Datagrid>
        </List>
    );
};
