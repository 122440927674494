import * as React from "react";
import { useEffect } from "react";
import {
    Datagrid,
    List,
    TextField,
    DateField,
    useRefresh,
    useRecordContext
} from "react-admin";
import { Link } from '@material-ui/core';
import CopyToClipboardButton from "../../common/CopyToClippboardButton";

const LinkCopyField = ({ source }) => {
    const record = useRecordContext();
    return <div className="data-cell__copy-to-clipboard">
        <Link href={`/#/posts/${record.eventId}/show`} className="launch-link" >
            {record.eventId}
        </Link>
        <CopyToClipboardButton string source={record.eventId} className="launch-link" />
    </div>;
};
LinkCopyField.defaultProps = {
    textAlign: 'left',
};

const CopyField = ({ source }) => {
    const record = useRecordContext();
    return <div className="data-cell__copy-to-clipboard">
        <span>{record[`${source}`]}</span>
        <CopyToClipboardButton string source={record[`${source}`]} className="launch-link" />
    </div>;
};
CopyField.defaultProps = {
    textAlign: 'left',
};


export const FanoutList = () => {
    const refresh = useRefresh();
    const refreshInterval = 60 * 1000; // 60 seconds

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refresh, refreshInterval]);

    return (
        <List
            pagination={false}
            perPage={100}
            sort={{ field: "startDate", order: "DESC" }}
        >
            <Datagrid optimized rowClick="show" bulkActionButtons={false}>
                <CopyField source="id" sortable={false} />
                <LinkCopyField source="eventId" label={"Event id"} sortable={false} />
                <TextField source="event.eventName" label={"Event name"} sortable={false} />
                <DateField source="event.startDate" showTime={true} sortable={false} locales="en-US" />
                <TextField source="state" sortable={false} />
                <TextField source="services" sortable={false} />
                <TextField source="data.maxIdleCpus" label={"Max Idle"} sortable={false} />
                <TextField source="data.totalViewers" label={"Total viewers"} sortable={false} />
            </Datagrid>
        </List>
    );
};
