import FirebaseClient from "./FirebaseClient";
import ComputeProvider from './DataProvider/Compute';

class GapiClient {
    constructor(store) {
        this._store = store;
        this.app = null;
        this.config = {
            apiKey: "AIzaSyC1Y-Cm1SB-iMY6w9pGftauj7-E7pXRzRA",
            clientId: "798564363761-463qvobf8mmvequ7niu15vl532tbnqq5.apps.googleusercontent.com",
            client_id: "798564363761-463qvobf8mmvequ7niu15vl532tbnqq5.apps.googleusercontent.com",
            discoveryDocs: [
                "https://www.googleapis.com/discovery/v1/apis/oauth2/v1/rest",
                "https://www.googleapis.com/discovery/v1/apis/compute/v1/rest",
                "https://iam.googleapis.com/$discovery/rest?version=v1"
            ],
            scope: "email profile " +
                "https://www.googleapis.com/auth/cloud-platform " +
                "https://www.googleapis.com/auth/compute " +
                "https://www.googleapis.com/auth/compute.readonly ",
            projectId: "nofilterbroadcast-net",
            // authDomain: "streemz-net.firebaseapp.com",
            // databaseURL: "https://streemz-net-default-rtdb.firebaseio.com",
            // storageBucket: "streemz-net.appspot.com",
            // messagingSenderId: "104795671843",
            // appId: "1:104795671843:web:7e60433eff4dd8091f9096",
            // measurementId: "G-G278WLTQPD"
        };
        this.hasAccessToProject = null;
        this.initGapi = this.initGapi.bind(this);
        this.getDataProvider = this.getDataProvider.bind(this);
        this.getAuthAdapter = this.getAuthAdapter.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.checkProjectIAMPolicy = this.checkProjectIAMPolicy.bind(this);
        this.onSignInChanged = this.onSignInChanged.bind(this);
        this.authClient = null;
        this.Authenticated = new Promise((resolve, reject) => {
            this.onSignIn = resolve;
            this.onSignOut = reject;
            // setTimeout(()=>reject(), 30000);
        });

    }

    setStore(store) {
        this._store = store;
    }

    async initGapi() {
        let gapi = window.gapi;
        console.log('gapi', gapi)
        await new Promise((resolve, reject) => {
            // NOTE: the 'auth2' module is no longer loaded.
            gapi.load('client', { callback: resolve, onerror: reject });
        });
        await gapi.client.init({
            // NOTE: OAuth2 'scope' and 'client_id' parameters have moved to initTokenClient().
        }).then(() => {  // Load the Calendar API discovery document.
            // gapi.client.load('https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest');
            gapi.client.load('https://www.googleapis.com/discovery/v1/apis/people/v1/rest');
            gapi.client.load('https://www.googleapis.com/discovery/v1/apis/plus/v1/rest');
            gapi.client.load('https://www.googleapis.com/discovery/v1/apis/oauth2/v1/rest');

        });

        new Promise((resolve, reject) => {
            this.computeProvider = new ComputeProvider(window.gapi, this.config);
            this.firestoreProvider = FirebaseClient.getFirestoreProvider();
            this.rtdbProvider = FirebaseClient.getRealtimeDBProvider();
            this.functionCallProvider = FirebaseClient.getFunctionCallProvider();

        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return gapi;

    }
    async onSignInChanged(tokenResponse) {
        const { access_token } = tokenResponse
        let gapi = window.gapi;
        let config = this.config;
        console.log('gapi.client.getToken()', gapi.client.getToken())
        setTimeout(() => {
            console.log('gapi.client.getToken()', gapi.client.getToken())
        }, 5000)
        if (access_token) {
            let resp = await gapi.client.oauth2.userinfo.get();
            if (resp.status) {
                let { id, name, picture, email } = resp.result;
                console.log('user', resp.result);
                console.log('gapi: user signed in!', { id, name, picture, email })
            }
            let hasAccess = await this.checkProjectIAMPolicy(config.projectId);
            if (!hasAccess) {
                console.log('checkProjectIAMPolicy failed');
                return this.onSignOut();
            }
            if (this.onSignIn)
                this.onSignIn();
            FirebaseClient.authWithGapi(tokenResponse);
            // .then(this.onSignIn)
            // .catch(this.onSignOut);
        } else {
            console.log('gapi: user is not signed in');
            FirebaseClient.signOut();
            this.onSignOut()
        }
    }

    getConfig() {
        return { ...this.config };
    }

    getAuthAdapter() {
        console.log('getAuthAdapter');
        let config = this.getConfig();
        const authProvider = {
            login: async () => {
                console.log('AuthProvider.login');
                // if (!this.authClient) {
                //     return Promise.reject();
                // }
                this.Authenticated = new Promise((resolve, reject) => {
                    // Resolve to false in 10s
                    setTimeout(() => reject(), 10000);
                    this.onSignIn = resolve;
                    this.onSignOut = reject;
                });
                // this.onSignInChanged = null;
                try {
                    const client = window.google.accounts.oauth2.initTokenClient({
                        ...config,
                        callback: async (tokenResponse) => {
                            console.log('Gapi initialized', tokenResponse);
                            if (tokenResponse && tokenResponse.access_token) {
                                this.authClient = client;
                                console.log('Token should be set by now');
                                this.onSignInChanged(tokenResponse);
                                // gapi.client.setApiKey(config.apiKey);
                                // gapi.client.setToken(tokenResponse.access_token)
                                // resolve(gapi)

                            }
                        },
                    });

                    return client.requestAccessToken();
                } catch (e) {
                    console.log('GAPI Error', e);
                    return Promise.reject();
                    // reject(e.message);
                }
            },
            logout: async () => {
                console.log('AuthProvider.logout');
                // if (!this.authClient) {
                //     return Promise.reject();
                // }
                this.onSignIn = () => { };
                this.onSignOut = () => { };
                // this.Authenticated = Promise.reject().catch();
                //return Promise.resolve();
                this.Authenticated = new Promise((resolve, reject) => {
                    this.onSignIn = resolve;
                    this.onSignOut = reject;
                    setTimeout(() => reject(), 30000);
                });
                return window.google.accounts.oauth2.revoke();
            },
            checkAuth: async () => {
                // return Promise.reject();
                console.log('checkAuth', this.Authenticated)
                return this.Authenticated;
            },
            checkError: (error) => {
                console.error('Checking error', error)
                // if(error) {
                //     if(error.message == 'ra.notification.data_provider_error') {
                //         console.warn('Some data provider error [%s]', error.message);
                //         return Promise.resolve();
                //     }
                //     console.error('AuthProvider.checkError error [%s]', error.message, error.stack);
                //     return Promise.resolve();
                //     if (error.message)
                //         return Promise.reject();
                //     const status = error.status;
                //     if (status === 401 || status === 403) {
                //         return Promise.reject();
                //     }
                // }
                // other error code (404, 500, etc): no need to log out
                return Promise.resolve();
            },
            getIdentity: async () => {
                let gapi = window.gapi;
                try {
                    try {

                        let resp = await gapi.client.oauth2.userinfo.get();
                        if (resp && resp.status) {
                            let { id, name, picture } = resp.result;
                            console.log('user', resp.result);
                            return Promise.resolve({ id, fullName: name, avatar: picture })
                        } else {
                            return Promise.reject('Could not get user data');
                        }
                    } catch (error) {
                        return Promise.reject(error);
                    }

                } catch (e) {
                    console.error(e)
                    Promise.reject();
                }
            },
            getPermissions: () => {
                console.log('AuthProvider.getPermissions');
                //	google.accounts.oauth2.initTokenClient({
                // 		client_id: "***************************************.apps.googleusercontent.com",
                // 		scope: "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/drive.appdata",
                // 		prompt: "none",
                // 		callback: rcv
                // 	}).requestAccessToken();
                //
                // 	function rcv(tok) {
                // 		if (google.accounts.oauth2.hasGrantedAllScopes(tok, "https://www.googleapis.com/auth/calendar.readonly"))
                // 			permissions.view_calendars = true;
                //
                // 		if (google.accounts.oauth2.hasGrantedAllScopes(tok, "https://www.googleapis.com/auth/drive.appdata"))
                // 			permissions.drive_appdata = true;
                // 	}
                return Promise.resolve('');
            },
            getRoles: () => {
                console.log('AuthProvider.getRoles');
                Promise.reject('Not implemented')
            },
        };

        return authProvider;
    }

    getDataProvider() {
        let config = this.getConfig();
        console.log('getDataProvider', config);

        const dataProvider = {

            getList: async (resource, params) => {
                // console.log('getList', resource, params);
                try {
                    switch (resource) {
                        case 'instances':
                            return this.computeProvider.getList(resource, params);
                        case 'subscription_types':
                        case 'user_subscriptions':
                            return this.firestoreProvider.getList(resource, params);
                        case 'events':
                        case 'posts':
                        case 'users':
                        case 'stats':
                        case 'tips':
                        case 'tickets':
                        case 'tenants':
                        case 'fanouts':
                        case 'ai':
                            return this.functionCallProvider.getList(resource, params);
                        case 'billing':
                            return this.rtdbProvider.getList('billing/event', params);
                        default:
                            console.warn('Unknown resource: %s', resource);
                    }
                } catch (e) {
                    console.error(e.message, e)
                    return { error: e.message }
                }
            },

            getOne: async (resource, params) => {
                try {
                    console.log('getOne', resource, params);
                    switch (resource) {
                        case 'instances':
                            return this.computeProvider.getOne(resource, params);
                        case 'subscription_types':
                        case 'user_subscriptions':
                            return this.firestoreProvider.getOne(resource, params);
                        case 'events':
                        case 'posts':
                        case 'users':
                        case 'tips':
                        case 'tickets':
                        case 'tenants':
                        case 'stats':
                        case 'fanouts':
                        case 'ai':
                            return this.functionCallProvider.getOne(resource, params);
                        case 'billing':
                            return this.rtdbProvider.getOne('billing/event', params);
                        default:
                            console.warn('Unknown resource: %s', resource);
                            return { error: `Unknown resource: ${resource}` }
                    }
                } catch (e) {
                    return { error: e.message }
                }
            },

            getMany: (resource, params) => {
                console.log('getMany', resource, params);
                // return Promise.reject();
                try {
                    switch (resource) {
                        // case 'instances':
                        //     return this.computeProvider.handleAction('delete', resource, params);
                        case 'subscription_types':
                        case 'events':
                        case 'posts':
                        case 'users':
                        case 'tips':
                        case 'tickets':
                        case 'tenants':
                            return this.firestoreProvider.getMany(resource, params);
                        default:
                            return Promise.reject();
                    }
                } catch (e) {
                    return Promise.reject();
                }
                // const query = {
                //     filter: JSON.stringify({ ids: params.ids }),
                // };
                // const url = `${apiUrl}/${resource}?${stringify(query)}`;
                // return httpClient(url).then(({ json }) => ({ data: json }));
            },

            getManyReference: (resource, params) => {
                console.log('getManyReference', resource, params);
                return Promise.reject();
            },

            update: (resource, params) => {
                try {
                    switch (resource) {
                        case 'events':
                        case 'posts':
                        case 'subscription_types':
                        case 'user_subscriptions':
                        case 'users':
                        case 'tips':
                        case 'tickets':
                        case 'tenants':
                            return this.firestoreProvider.update(resource, params);
                        default:
                            console.warn('Unknown resource: %s', resource);
                    }
                } catch (e) {
                    return { error: e.message }
                }
                // httpClient(`${apiUrl}/${resource}/${params.id}`, {
                //     method: 'PUT',
                //     body: JSON.stringify(params.data),
                // }).then(({json}) => ({data: json}))
            },

            updateMany: (resource, params) => {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids}),
                // };
                // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                //     method: 'PUT',
                //     body: JSON.stringify(params.data),
                // }).then(({ json }) => ({ data: json }));
            },

            create: async (resource, params) => {
                console.log('GapiClient create', resource, params)
                try {
                    switch (resource) {
                        case 'instances':
                            console.log('About to create instance')
                            return this.computeProvider.handleAction('create', resource, params);
                        case 'events':
                        case 'posts':
                        case 'subscription_types':
                        case 'user_subscriptions':
                        case 'tips':
                        case 'tickets':
                        case 'tenants':
                            return this.firestoreProvider.create(resource, params);
                        default:
                            console.warn('Unknown resource: %s', resource);
                    }
                } catch (e) {
                    return { error: e.message }
                }
            },

            delete: async (resource, params) => {
                try {
                    switch (resource) {
                        case 'instances':
                            return this.computeProvider.handleAction('delete', resource, params);
                        case 'user_subscriptions':
                            return this.firestoreProvider.delete(resource, params);
                        default:
                            console.warn('Unknown resource: %s', resource);
                    }
                } catch (e) {
                    return { error: e.message }
                }
            },

            deleteMany: (resource, params) => {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids}),
                // };
                // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                //     method: 'DELETE',
                //     body: JSON.stringify(params.data),
                // }).then(({ json }) => ({ data: json }));
            },
            // Instances speciffic
            reset: async (resource, params) => {

                switch (resource) {
                    case 'instances':
                        return
                    default:
                        console.warn('Unknown resource: %s', resource);
                }
            },
            suspend: async (resource, params) => {

                switch (resource) {
                    case 'instances':
                        return this.computeProvider.handleAction('suspend', resource, params);
                    default:
                        console.warn('Unknown resource: %s', resource);
                }
            },
            resume: async (resource, params) => {

                switch (resource) {
                    case 'instances':
                        return this.computeProvider.handleAction('resume', resource, params);
                    default:
                        console.warn('Unknown resource: %s', resource);
                }
            },
            activate: async (resource, params) => {
                // console.log('activate data', resource, params);
                // return Promise.reject('WIP')

                switch (resource) {
                    case 'subscription_types':
                        return this.firestoreProvider.activate(resource, params);
                    default:
                        console.warn('Unknown resource: %s', resource);
                }
            },
            deactivate: async (resource, params) => {
                console.log('deactivate data', resource, params)

                switch (resource) {
                    case 'subscription_types':
                        return this.firestoreProvider.deactivate(resource, params);
                    default:
                        console.warn('Unknown resource: %s', resource);
                }
            },
            stop: (resource, params) => this.computeProvider.handleAction('stop', resource, params),
            start: (resource, params) => this.computeProvider.handleAction('start', resource, params),
            restart: (resource, params) => {
                let { id } = params;
                switch (resource) {
                    case 'fanouts':
                        return this.functionCallProvider.restartFanout(resource, { id });
                    default:
                        console.warn('Unknown resource: %s', resource);
                }

            },
            // delete:(resource, params) => this.computeProvider.handleAction('delete', resource, params),
        }
        return dataProvider;
    }
    async checkProjectIAMPolicy(projectId) {
        console.log('checkProjectIAMPolicy 0', this.hasAccessToProject);
        if (this.hasAccessToProject === null) {
            let url = `https://cloudresourcemanager.googleapis.com/v1/projects/${projectId}:getIamPolicy`;
            this.hasAccessToProject = window.gapi.client.request({ path: url, method: 'post' }).then((result) => {
                return result && !result.error;
            }).catch(e => {
                console.error(e.message, e);
                return false;
            });
            console.log('checkProjectIAMPolicy 1', this.hasAccessToProject);
        }
        return this.hasAccessToProject;
    }
}
// eslint-disable-next-line
export default new GapiClient();
