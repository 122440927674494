import { useRecordContext } from "react-admin";
import moment from "moment/moment";
import * as React from "react";
import get from 'lodash/get';

const FSDateTimeField = ({ source }) => {
    const record = useRecordContext();
    const m = moment(get(record, source)._seconds * 1000);
    return record ? <span>{m.format("L") + ' ' + m.format("LTS")}</span> : null;
}
FSDateTimeField.defaultProps = { label: 'Date' };

export default FSDateTimeField;