import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    List, 
    required, 
    // SearchInput, 
    SelectInput,
    TextField,
    useListContext,
    useRecordContext, Button, Toolbar
} from 'react-admin';
import * as React from "react";
import { Link } from "@material-ui/core";
import { convertMsToTime } from "../../../utils/HelperFunctions";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CopyToClipboardButton from '../../common/CopyToClippboardButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const AuthorField = () => {
    const record = useRecordContext();
    const { author, uid } = record;
    return record ?
        <Link href={`/#/users/${uid}/show/`} className="launch-link" >
            {author || uid}
        </Link> : null;
}
AuthorField.defaultProps = { label: 'Author' };

const IdField = () => {
    const record = useRecordContext();
    return record && record.id ?
       <div className="data-cell__copy-to-clipboard"><span>{record.id}</span><CopyToClipboardButton string source={record.id} className="launch-link"/></div>: null;
}
IdField.defaultProps = { label: 'Id' };


export const EventList = (props) => {

    const postFilters = [
        // <SearchInput source="filter_by" alwaysOn />,
        <SelectInput
            validate={required()}
            source="time"
            label="Time"
            autowidth="true"
            choices={[
                { id: "day", name: "Day" },
                { id: "week", name: "Week" },
                { id: "month", name: "Month" }
            ]}
            sx={{ width: "25%", marginBottom: "0" }}
            alwaysOn
        />
    ];

    const Paginatior = (params) => {
        const { isLoading, page, /*perPage, total,*/ setPage } = useListContext();

        return <Toolbar>
            <Button
                key="home"
                disabled={isLoading}
                onClick={(event) => {
                    setPage(1)
                    event.preventDefault();
                    event.stopPropagation();
                }}>
                <KeyboardDoubleArrowLeftIcon />  Home
            </Button>
            <Button
                key="previous"
                disabled={isLoading || page < 2}
                onClick={(event) => {
                    setPage(page - 1)
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                <ChevronLeft /> Previous
            </Button>
            <Button
                key="next"
                disabled={isLoading}
                onClick={(event) => {
                    setPage(page + 1)
                    event.preventDefault();
                    event.stopPropagation();
                }}>
                Next  <ChevronRight />
            </Button>
        </Toolbar>
    }

    let renderDuration = (record) => {
        if ((!record) || (record && !record.estDuration))
            return "00:00:00"

        let duration = convertMsToTime(record.estDuration * 60 * 1000) || "00:00:00";
        
        return `${duration}`;
    }

    return (
        <>
            <List
                {...props}
                sort={{ field: "startDate", order: "ASC" }}
                filters={postFilters}
                pagination={<Paginatior />}
                filterDefaultValues={{ time: 'week' }}
                actions={null}
            >
                <Datagrid optimized rowClick="show" bulkActionButtons={false}>
                    <TextField source="title" sortable={false} />
                    <IdField source="id" sortable={false} />
                    <AuthorField source="author" sortable={false} />
                    <TextField source="status" sortable={false} />
                    <TextField source="visibility" label="Type" sortable={false} />
                    <BooleanField source="live" sortable={false} TrueIcon={CheckIcon} FalseIcon={CloseIcon} />
                    <DateField source="startDate" showTime={true} sortable={false} locales="en-US" />
                    <FunctionField label="Duration" render={renderDuration} sortable={false} />
                </Datagrid>
            </List>
        </>
    );
};