import { useRecordContext } from "react-admin";
import moment from "moment/moment";
import * as React from "react";
import get from 'lodash/get';

const DateTimeField = ({ source }) => {
    const record = useRecordContext();
    const m = moment(get(record, source));
    return record ? <span>{m.format("L") + ' ' + m.format("LTS")}</span> : null;
}
DateTimeField.defaultProps = { label: 'Date' };

export default DateTimeField;