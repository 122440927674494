import React, { Component } from 'react';
import Cropper from 'react-easy-crop';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import firebaseClient from '../../utils/FirebaseClient';
import { makeid } from '../../utils/HelperFunctions';

export default class CropperFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: props.file || null,
            addFileDisabled: false,
            croppedAreaPixels: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 16 / 9,
            imgUrl: null,
            cropView: false
        }

        this.addFileRef = React.createRef();
        this.cancelCrop = this.cancelCrop.bind(this);
        this.saveCropImage = this.saveCropImage.bind(this);
        this.handleClickAddImage = this.handleClickAddImage.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { file } = this.props;

        if (file && file !== prevProps.file) {
            this.setState({ file: file });
        }
    }

    async handleSetCropImage(event) {
        if (event.target.files && event.target.files[0]) {
            let aspectRation = 16 / 9;

            this.setState({
                addBannerImgDisabled: true
            });
            aspectRation = 8 / 1;


            let img = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                this.setState({
                    imgUrl: reader.result,
                    cropView: true,
                    aspect: aspectRation
                });
            }

            if (img) {
                reader.readAsDataURL(img);
            }
        }
    }

    handleClickAddImage() {
        if (this.addFileRef && this.addFileRef.current && this.addFileRef.current.click) {
            this.addFileRef.current.value = null;
            this.addFileRef.current.click();
        }
    }

    createImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })
    }

    getRadianAngle(rotation) {
        return (rotation * Math.PI) / 180
    }

    rotateSize(width, height, rotation) {
        const rotRad = this.getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    async getCroppedImg(imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
        const image = await this.createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return null
        }

        const rotRad = this.getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        )

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0)

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(file)
            }, 'image/jpeg')
        })
    }

    saveCropImage() {
        const { imgUrl, croppedAreaPixels } = this.state;
        const { uid, handleAddImage } = this.props;

        this.setState({
            cropView: false
        }, async () => {
            if (uid) {
                let id = `${uid}/${makeid(10)}`;
                const croppedImage = await this.getCroppedImg(imgUrl, croppedAreaPixels);

                let type = 'event';

                firebaseClient.addImageToStorage(croppedImage, type, id).then(async (res) => {
                    let newState = {
                        croppedAreaPixels: null,
                        crop: { x: 0, y: 0 },
                        zoom: 1,
                        aspect: 16 / 9,
                        imgUrl: null,
                    }

                    newState.file = res;
                    newState.addFileDisabled = false;

                    this.setState(newState, () => {
                        if (handleAddImage) {
                            handleAddImage(res);
                        }
                    });
                });
            }
        });
    }

    cancelCrop() {
        this.setState({
            croppedAreaPixels: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 16 / 9,
            imgUrl: null,
            cropView: false,
            addFileDisabled: false
        });
    }

    render() {
        const { crop, zoom, aspect, imgUrl, cropView, addFileDisabled, file } = this.state;
        const { handleAddImage } = this.props;

        return (
            <div className='add-file-form'>
                <div
                    onClick={this.handleClickAddImage}
                    className={`select-add-text ${addFileDisabled ? 'disabled' : ''}`}
                >
                    {file ?
                        <span className='file-line'>
                            <img
                                src={file}
                                className='file-image'
                                alt='img'
                                loading='lazy'
                            />
                            <button
                                className='btn btn-replace-image'
                            >
                                <AddPhotoAlternateIcon fontSize='medium' />
                            </button>
                            <button
                                className='btn btn-delete-image'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                        file: null
                                    }, () => {
                                        if (handleAddImage) {
                                            handleAddImage(null);
                                        }
                                    });
                                }}
                            >
                                <DeleteIcon fontSize='medium' />
                            </button>
                        </span>
                        :
                        <span className='file-line'>
                            <button
                                className='btn btn-add-image'
                            >
                                <AddPhotoAlternateIcon fontSize='medium' />
                            </button>
                        </span>
                    }
                    <input
                        type="file"
                        className='add-file'
                        ref={this.addFileRef}
                        accept="image/png,image/jpeg,image/svg+xml"
                        onChange={(event) => this.handleSetCropImage(event)}
                    />
                </div>
                {cropView ?
                    <div className='crop-container'>
                        <Cropper
                            image={imgUrl}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={(crop) => this.setState({ crop: crop })}
                            onCropComplete={(croppedArea, croppedAreaPixels) => this.setState({ croppedAreaPixels: croppedAreaPixels })}
                            onZoomChange={(zoom) => this.setState({ zoom: zoom })}
                        />
                        <div className='crop-buttons'>
                            <button
                                onClick={this.cancelCrop}
                                className='btn btn-crop-close'
                            >
                                Cancel
                            </button>
                            <button
                                onClick={this.saveCropImage}
                                className='btn btn-crop-done'
                            >
                                Crop
                            </button>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}
