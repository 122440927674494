import imageCompression from 'browser-image-compression';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/compat/messaging";
import "firebase/compat/firestore"
import * as appActions from '../reducers/app';
import * as firebaseActions from '../reducers/firebase';
import {
    documentId,
    doc,
    onSnapshot,
    query,
    collection
} from "firebase/firestore";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithCredential
} from "firebase/auth";
import moment from "moment/moment";
import { getTenantId, isB2B } from './HelperFunctions';

class FirebaseClient {
    constructor(store) {
        this._store = store;
        this.app = null;

        const config = {
            apiKey: process.env.REACT_APP_apiKey,
            authDomain: process.env.REACT_APP_authDomain,
            databaseURL: process.env.REACT_APP_databaseURL,
            projectId: process.env.REACT_APP_projectId,
            storageBucket: process.env.REACT_APP_storageBucket,
            messagingSenderId: process.env.REACT_APP_messagingSenderId,
            appId: process.env.REACT_APP_appId,
            measurementId: process.env.REACT_APP_measurementId
        };
        this.config = config;
    }

    setStore(store) {
        this._store = store;
    }

    initFirebase() {
        let fireBase = new Promise((resolve, reject) => {
            try {
                // initializeApp(this.config)
                firebase.initializeApp(this.config);
                firebase.analytics();
                // requestMessagingPermission();
                // workerInstance = new Worker(new URL('./messaging-service-worker.js', import.meta.url));
                if (firebase.app().name) {
                    console.log('Firebase initialized');
                    this._store.dispatch(firebaseActions.initFirebase({ init: true }));
                    resolve(firebase);
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return fireBase;

    }

    authWithGapi(id, access_token, result) {
        const auth = getAuth();

        let fireBase = new Promise((resolve, reject) => {
            try {

                const cred = GoogleAuthProvider.credential(result.credential)

                // Sign in with credential from the Google user.
                return signInWithCredential(auth, cred)
                    .then(({ user }) => {
                        console.log('firebase: user signed in!', {
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL,
                        });
                        resolve(user)
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                // // const credential = firebase.auth.GoogleAuthProvider.credentialFromResult(result);
                // const credential = firebase.auth.GoogleAuthProvider.credential(null,access_token);
                // console.log('authWithGapi credential', credential)
                // return firebase.auth().signInWithCredential(credential)
                //     .then(({ user }) => {
                //         console.log('firebase: user signed in!', {
                //             displayName: user.displayName,
                //             email: user.email,
                //             photoURL: user.photoURL,
                //         });
                //         resolve(user)
                //     })
                //     .catch((error) => {
                //         console.error(error);
                //     })

            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return fireBase;

    }

    authWithGapix(authResponse) {
        let fireBase = new Promise((resolve, reject) => {
            try {

                const credential = firebase.auth.GoogleAuthProvider.credential(
                    authResponse.id_token,
                    authResponse.access_token
                )
                return firebase.auth().signInWithCredential(credential)
                    .then(({ user }) => {
                        console.log('firebase: user signed in!', {
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL,
                        });
                        resolve(user)
                    })
                    .catch((error) => {
                        console.error(error);
                    })

            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return fireBase;

    }

    isSignedIn() {
        console.log('FirebaseClient.isSignedIn', firebase.auth().currentUser);
        return !!firebase.auth().currentUser;
    }

    signOut() {
        try {
            return firebase.auth().signOut().then(() => {
                console.log('Firebase user signed out');
            });
        } catch (e) {
            console.error(e)
        }
    }

    getConfig() {
        return this.config;
    }

    addFileToStorage(file, path) {
        let storageAdd = new Promise((resolve, reject) => {
            // var storageRef = firebase.storage().refFromURL('gs://nofilter-ai-uploads/');
            var storageRef = firebase.storage().ref();

            if (file) {
                // Create the file metadata
                var metadata = {
                    contentType: file && file.type ? file.type : 'image/jpeg'
                };

                // Upload file and metadata to the object 'images/mountains.jpg'
                var uploadTask = storageRef.child(path).put(file, metadata);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    function (snapshot) {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        // console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                // console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                // console.log('Upload is running');
                                break;
                            default:
                                console.warn('dafault: addFileToStorage', snapshot.state)
                        }
                    }, (error) => {
                        reject(error)
                        switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;

                            case 'storage/canceled':
                                // User canceled the upload
                                break;

                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                            default:
                                console.error('dafault: addFileToStorage', error.code);
                        }
                    }, function () {
                        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                            // console.log('File available at', downloadURL);
                            return resolve(downloadURL);
                        });
                    });
            }
        })
        return storageAdd;
    }

    getAuthAdapter() {
        console.log('getAuthAdapter');
        const authProvider = {
            login: async ({ adapter }) => {
                console.log('AuthProvider.login', adapter);
                if (adapter !== 'google') {
                    return Promise.reject();
                }
                return this.logIn(adapter).then(() => {
                    return new Promise((resolve) => {
                        setTimeout(resolve, 2000);
                    })
                });
            },
            logout: async () => {
                console.log('AuthProvider.logout');
                // return Promise.resolve()
                return this.logOut().then(() => Promise.resolve());
                //return Promise.resolve();
            },
            checkAuth: async () => {
                let state = this._store.getState();
                console.log('AuthProvider.checkAuth', firebase.auth().currentUser, state);
                //return Promise.resolve(false)
                return (firebase.auth().currentUser
                    ? state.firebase.user && state.firebase.user.administrator ? Promise.resolve(true) : Promise.reject()
                    : Promise.reject());
            },
            checkError: async (error) => {
                console.log('AuthProvider.checkAuth', error.message, error);
                // const status = error.status;
                // if (status === 401 || status === 403) {
                //     return Promise.reject();
                // }
                // other error code (404, 500, etc): no need to log out
                return Promise.resolve();
            },
            getIdentity: async () => {
                console.log('AuthProvider.getIdentity', firebase.auth().currentUser);
                return Promise.resolve(firebase.auth().currentUser);
            },
            getPermissions: async () => {
                console.log('AuthProvider.getPermissions');
                return Promise.resolve('');
            },
            getRoles: async () => {
                console.log('AuthProvider.getRoles');
                Promise.reject('Not implemented')
            },
        };

        return authProvider;
    }

    getRealtimeDBProvider() {
        // Get a reference to the service
        // Get a reference to the database service
        const db = firebase.database();
        let resource2collection = {
            'fanouts': 'fanout/instances',
            'billing/event': 'billing/event',
        }
        console.log('About to return Firestore provider', documentId());

        return {
            getList: (resource, params) => {
                const { page, perPage } = params.pagination || {};
                let { field, order } = params.sort;
                console.log('filter', page, perPage, field, order.toLowerCase(), (((page ? page : 1) - 1) * (perPage || 100)), perPage || 100,
                )

                let docRef = db.ref(resource2collection[resource]);
                // TODO: fix orderby
                let getChildrenObjects = (data) => {
                    if (!data) {
                        return null;
                    }
                    let res = Object.entries(data).map(([id, child]) => Object.assign({}, child, { id }));

                    return res;
                };

                return docRef.get().then(async (documentSnapshots) => {
                    // console.log("Document documentSnapshots:", documentSnapshots);
                    if (!documentSnapshots || !documentSnapshots.val || !documentSnapshots.exists()) {
                        return { data: [], total: 0 };
                    }
                    console.log('Got doc snapshots', documentSnapshots.val())
                    // return {data: [], total:0};
                    // Get the last visible document
                    let data = await getChildrenObjects(documentSnapshots.val());
                    // convert dates
                    // data = data.map(item => {
                    //     if(item.valid_from && item.valid_from.seconds)
                    //         item.valid_from = item.valid_from.seconds*1000;
                    //     if(item.valid_to && item.valid_to.seconds)
                    //         item.valid_to = item.valid_to.seconds*1000;
                    //     return item;
                    // })
                    if (data) {
                        console.log("Document data:", data);
                        return { data, total: data.length };
                    } else {
                        // doc.data() will be undefined in this case
                        console.warn("No such document!");
                        return { data: [], total: 0 };
                    }
                }).catch((e) => {
                    console.error('Call to getList failed [%s]', e.message || 'unknown', resource, params, e);
                    return { error: `Call to getList failed ${e.message}`, data: [], total: 0 };
                })
            },

            getOne: (resource, params) => {
                let docRef = db.ref(resource2collection[resource]).child(params.id);

                return docRef.get().then(async (documentSnapshots) => {
                    // Get the last visible
                    if (documentSnapshots.exists()) {
                        console.log("Document data:", documentSnapshots.val());
                        let data = documentSnapshots.val();
                        if (data.valid_from && data.valid_from.seconds)
                            data.valid_from = data.valid_from.seconds * 1000;
                        if (data.valid_to && data.valid_to.seconds)
                            data.valid_to = data.valid_to.seconds * 1000;
                        if (data.last_payment_date && data.last_payment_date.seconds)
                            data.last_payment_date = data.last_payment_date.seconds * 1000;
                        if (data.next_payment_due_date && data.next_payment_due_date.seconds)
                            data.next_payment_due_date = data.next_payment_due_date.seconds * 1000;
                        return { data: { ...data, id: params.id } };
                    } else {
                        // doc.data() will be undefined in this case
                        console.warn("No such document!");
                        return Promise.reject("No such document!");
                    }
                }).catch((e) => {
                    console.error('Call to getOne failed [%s]', e.message || 'unknown', resource, params, e);
                    return { error: `Call to getOne failed ${e.message}`, data: null };
                });
            },

            getMany: (resource, params) => {
                return { data: [], total: 0 };
            },

        };
    }

    getFirestoreProvider() {
        // Get a reference to the service
        const db = firebase.firestore();
        let resource2collection = {
            'events': getTenantId('calendar'),
            'posts': getTenantId('posts'),
            'subscription_types': 'subscription_type',
            'user_subscriptions': 'user_subscription',
            'users': 'users',
            'tenants': 'tenants',
        }
        console.log('About to return Firestore provider', documentId())
        return {
            getList: (resource, params) => {
                console.log('--- Firestore provider ------')
                console.log('getList(%s)', resource, params);
                const { page, perPage } = params.pagination || {};
                let { field, order } = params.sort;
                if (field === 'id') {
                    field = documentId();
                    order = 'asc';
                }
                const filter = params.filter || null;
                console.log('filter', page, perPage, field, order.toLowerCase(), (((page ? page : 1) - 1) * (perPage || 100)), perPage || 100,
                )

                let docRef = db.collection(resource2collection[resource])
                    .orderBy(field, order.toLowerCase())
                    // .startAfter(((page?page:1)-1)  * (perPage||100))
                    // .limit(perPage||100)
                    ;
                if (filter && filter.field) {
                    console.log('getList(%s) filter', resource2collection[resource], filter.field, filter.operator, filter.value)
                    docRef = docRef.where(filter.field, filter.operator, filter.value);
                } else if (filter && Object.keys(filter).length) {
                    let keys = Object.keys(filter);
                    keys.forEach(key => {
                        if (key !== 'q')
                            docRef = docRef.where(key, '==', filter[key]);

                    });
                }
                // TODO: fix orderby
                console.log('HeRe', filter && filter.field)
                return docRef.get().then(async (documentSnapshots) => {
                    console.log("Document documentSnapshots:", documentSnapshots);
                    // Get the last visible document
                    let data = await getObjects(documentSnapshots);
                    // convert dates
                    data = data.map(item => {
                        if (item.valid_from && item.valid_from.seconds)
                            item.valid_from = item.valid_from.seconds * 1000;
                        if (item.valid_to && item.valid_to.seconds)
                            item.valid_to = item.valid_to.seconds * 1000;
                        if (item.last_payment_date && item.last_payment_date.seconds)
                            item.last_payment_date = item.last_payment_date.seconds * 1000;
                        if (item.next_payment_due_date && item.next_payment_due_date.seconds)
                            item.next_payment_due_date = item.next_payment_due_date.seconds * 1000;
                        return item;
                    })
                    if (data) {
                        console.log("Document data:", data);
                        return { data, total: 10000 } // !!!TODO: Fix total counter
                    } else {
                        // doc.data() will be undefined in this case
                        console.warn("No such document!");
                        return { data: [], total: 0 };
                    }
                }).catch((e) => {
                    console.error('Call to getList failed [%s]', e.message || 'unknown', resource, params, e);
                    return { error: `Call to getList failed ${e.message}`, data: [], total: 0 };
                });
            },

            getOne: (resource, params) => {
                let docRef = db.collection(resource2collection[resource]).doc(params.id);

                return docRef.get().then(async (documentSnapshots) => {
                    // Get the last visible
                    if (documentSnapshots.exists) {
                        console.log("Document data:", documentSnapshots.data());
                        let data = documentSnapshots.data();
                        if (data.valid_from && data.valid_from.seconds)
                            data.valid_from = data.valid_from.seconds * 1000;
                        if (data.valid_to && data.valid_to.seconds)
                            data.valid_to = data.valid_to.seconds * 1000;
                        if (data.last_payment_date && data.last_payment_date.seconds)
                            data.last_payment_date = data.last_payment_date.seconds * 1000;
                        if (data.next_payment_due_date && data.next_payment_due_date.seconds)
                            data.next_payment_due_date = data.next_payment_due_date.seconds * 1000;
                        return { data: { ...data, id: params.id } };
                    } else {
                        // doc.data() will be undefined in this case
                        console.warn("No such document!");
                        return Promise.reject("No such document!");
                    }
                }).catch((e) => {
                    console.error('Call to getOne failed [%s]', e.message || 'unknown', resource, params, e);
                    return { error: `Call to getOne failed ${e.message}`, data: null };
                });
            },

            getMany: (resource, params) => {
                console.log('getMany', resource, params);
                // const query = {
                //     filter: JSON.stringify({ ids: params.ids }),
                // };
                // const url = `${apiUrl}/${resource}?${stringify(query)}`;
                // return httpClient(url).then(({ json }) => ({ data: json }));
                const { ids } = params || [];
                console.log('filter', resource2collection[resource], ids, documentId());

                let docRef = db.collection(resource2collection[resource])
                    .where(documentId(), 'in', ids)
                    // .orderBy(field, order.toLowerCase())
                    // .startAfter(((page?page:1)-1)  * (perPage||100))
                    // .limit(perPage||100)
                    ;
                // TODO: fix orderby

                return docRef.get().then(async (documentSnapshots) => {
                    // console.log("Document documentSnapshots:", documentSnapshots);
                    // Get the last visible document
                    let data = await getObjects(documentSnapshots);
                    // convert dates
                    data = data.map(item => {
                        if (item.valid_from && item.valid_from.seconds)
                            item.valid_from = item.valid_from.seconds * 1000;
                        if (item.valid_to && item.valid_to.seconds)
                            item.valid_to = item.valid_to.seconds * 1000;
                        if (item.last_payment_date && item.last_payment_date.seconds)
                            item.last_payment_date = item.last_payment_date.seconds * 1000;
                        if (item.next_payment_due_date && item.next_payment_due_date.seconds)
                            item.next_payment_due_date = item.next_payment_due_date.seconds * 1000;
                        return item;
                    })
                    if (data) {
                        console.log("Get many data:", data);
                        return { data, total: 10000 } // !!!TODO: Fix total counter
                    } else {
                        // doc.data() will be undefined in this case
                        console.warn("No such document!");
                        return { data: [], total: 0 };
                    }
                }).catch((e) => {
                    console.error('Call to getList failed [%s]', e.message || 'unknown', resource, params, e);
                    return { error: `Call to getList failed ${e.message}`, data: [], total: 0 };
                });
            },

            getManyReference: (resource, params) => {
                // const { page, perPage } = params.pagination;
                // const { field, order } = params.sort;
                // const query = {
                //     sort: JSON.stringify([field, order]),
                //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                //     filter: JSON.stringify({
                //         ...params.filter,
                //         [params.target]: params.id,
                //     }),
                // };
                // const url = `${apiUrl}/${resource}?${stringify(query)}`;
                //
                // return httpClient(url).then(({ headers, json }) => ({
                //     data: json,
                //     total: parseInt(headers.get('content-range').split('/').pop(), 10),
                // }));
            },

            update: async (resource, params) => {
                console.log(`About to update ${resource}`, params);
                try {
                    switch (resource) {
                        case 'subscription_types': {
                            const data = await this.saveSubscriptionType(params.data, params.previousData);
                            return data;
                        }
                        case 'user_subscriptions': {
                            const data = await this.saveUserSubscription(params.data, params.previousData);
                            return data;
                        }
                        case 'users': {
                            const data = await this.saveUser(params.data, params.previousData);
                            return data;
                        }
                        case 'tenants': {
                            const data = await this.saveTenant(params.data, params.previousData);
                            return data;
                        }
                        default:
                            return Promise.reject();
                    }
                } catch (e) {
                    console.error('Call to getOne failed [%s]', e.message || 'unknown', resource, params, e);
                    return Promise.reject()
                }
            },

            updateMany: (resource, params) => {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids}),
                // };
                // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                //     method: 'PUT',
                //     body: JSON.stringify(params.data),
                // }).then(({ json }) => ({ data: json }));
            },

            create: async (resource, params) => {
                console.log(`About to create ${resource}`, params);
                switch (resource) {
                    case 'subscription_types': {
                        const data = await this.saveSubscriptionType(params.data);
                        return data;
                    }
                    case 'user_subscriptions': {
                        const data = await this.saveUserSubscription(params.data);
                        return data;
                    }
                    case 'tenants': {
                        const data = await this.saveTenant(params.data);
                        return data;
                    }
                    default:
                        return Promise.reject();
                }
            },

            delete: (resource, params) => {
                // httpClient(`${apiUrl}/${resource}/${params.id}`, {
                //     method: 'DELETE',
                // }).then(({json}) => ({data: json}))
            },

            deleteMany: (resource, params) => {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids}),
                // };
                // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                //     method: 'DELETE',
                //     body: JSON.stringify(params.data),
                // }).then(({ json }) => ({ data: json }));
            },
            activate: this.activate.bind(this),
            deactivate: this.activate.bind(this),
            restartFanout: this.restartFanout.bind(this),
        }
    }

    getFunctionCallProvider() {
        // Get a reference to the service
        const db = firebase.firestore();
        let resource2collection = {
            'events': getTenantId('calendar'),
            'posts': getTenantId('posts'),
            'subscription_types': 'subscription_type',
            'user_subscriptions': 'user_subscription',
            'users': 'users',
            'stats': 'stats_daily',
            'tips': 'tickets',
            'tickets': 'tickets',
            'tenants': 'tenants',
            'fanouts': 'fanouts',
            'ai': 'ai',
        }
        console.log('About to return Firestore provider', documentId())
        return {
            getList: async (resource, params) => {
                console.log('getList', resource, params);
                let newPosts = []

                switch (resource) {
                    case 'fanouts':
                        return await this.getFanouts(params);
                    case 'ai':
                        return await this.listSummaries();
                    case 'users':
                        const filter = params.filter || null;
                        let { search_by } = filter || {};
                        if (search_by && search_by.length) {
                            return await this.searchUsers({ search_by, field: isB2B() ? 'tenants' : '' });
                        } else {
                            return await this.getFeaturedUsers({ ambassador: true, premium: true, administrator: true, field: isB2B() ? 'tenants' : '' });
                        }
                    default:
                        newPosts = await this.getFirebaseCollectionValues(resource, params);
                }

                if (newPosts) {
                    //console.log("Document data:", data);
                    return { data: newPosts, total: 1000 }
                } else {
                    // doc.data() will be undefined in this case
                    console.warn("No such document!");
                    return { data: [], total: 0 };
                }

            },

            getOne: (resource, params) => {
                console.log('getOne', resource, params);

                switch (resource) {
                    case 'fanouts':
                        return this.getFanout(params);
                    case 'ai':
                        return this.getSummary(params.id);
                    default:
                        return this.getOne(resource2collection[resource], params.id);
                }
            },

            getMany: (resource, params) => {
                console.log('getMany', resource, params);
                // const query = {
                //     filter: JSON.stringify({ ids: params.ids }),
                // };
                // const url = `${apiUrl}/${resource}?${stringify(query)}`;
                // return httpClient(url).then(({ json }) => ({ data: json }));
                const { ids } = params || [];
                console.log('filter', resource2collection[resource], ids, documentId());

                let docRef = db.collection(resource2collection[resource])
                    .where(documentId(), 'in', ids)
                    // .orderBy(field, order.toLowerCase())
                    // .startAfter(((page?page:1)-1)  * (perPage||100))
                    // .limit(perPage||100)
                    ;
                // TODO: fix orderby

                return docRef.get().then(async (documentSnapshots) => {
                    // console.log("Document documentSnapshots:", documentSnapshots);
                    // Get the last visible document
                    let data = await getObjects(documentSnapshots);
                    // convert dates
                    data = data.map(item => {
                        if (item.valid_from && item.valid_from.seconds)
                            item.valid_from = item.valid_from.seconds * 1000;
                        if (item.valid_to && item.valid_to.seconds)
                            item.valid_to = item.valid_to.seconds * 1000;
                        if (item.last_payment_date && item.last_payment_date.seconds)
                            item.last_payment_date = item.last_payment_date.seconds * 1000;
                        if (item.next_payment_due_date && item.next_payment_due_date.seconds)
                            item.next_payment_due_date = item.next_payment_due_date.seconds * 1000;
                        return item;
                    })
                    if (data) {
                        console.log("Get many data:", data);
                        return { data, total: 10000 } // !!!TODO: Fix total counter
                    } else {
                        // doc.data() will be undefined in this case
                        console.warn("No such document!");
                        return { data: [], total: 0 };
                    }
                }).catch((e) => {
                    console.error('Call to getList failed [%s]', e.message || 'unknown', resource, params, e);
                    return { error: `Call to getList failed ${e.message}`, data: [], total: 0 };
                });
            },

            getManyReference: (resource, params) => {
                // const { page, perPage } = params.pagination;
                // const { field, order } = params.sort;
                // const query = {
                //     sort: JSON.stringify([field, order]),
                //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                //     filter: JSON.stringify({
                //         ...params.filter,
                //         [params.target]: params.id,
                //     }),
                // };
                // const url = `${apiUrl}/${resource}?${stringify(query)}`;
                //
                // return httpClient(url).then(({ headers, json }) => ({
                //     data: json,
                //     total: parseInt(headers.get('content-range').split('/').pop(), 10),
                // }));
            },

            update: async (resource, params) => {
                console.log(`About to update ${resource}`, params);
                try {
                    switch (resource) {
                        case 'subscription_types': {
                            const data = await this.saveSubscriptionType(params.data, params.previousData);
                            return data;
                        }
                        case 'user_subscriptions': {
                            const data = await this.saveUserSubscription(params.data, params.previousData);
                            return data;
                        }
                        case 'users': {
                            const data = await this.saveUser(params.data, params.previousData);
                            return data;
                        }
                        default:
                            return Promise.reject();
                    }
                } catch (e) {
                    console.error('Call to getOne failed [%s]', e.message || 'unknown', resource, params, e);
                    return Promise.reject()
                }
            },

            updateMany: (resource, params) => {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids}),
                // };
                // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                //     method: 'PUT',
                //     body: JSON.stringify(params.data),
                // }).then(({ json }) => ({ data: json }));
            },

            create: async (resource, params) => {
                console.log(`About to create ${resource}`, params);
                switch (resource) {
                    case 'subscription_types': {
                        const data = await this.saveSubscriptionType(params.data);
                        return data;
                    }
                    case 'user_subscriptions': {
                        const data = await this.saveUserSubscription(params.data);
                        return data;
                    }
                    default:
                        return Promise.reject();
                }
            },

            delete: (resource, params) => {
                // httpClient(`${apiUrl}/${resource}/${params.id}`, {
                //     method: 'DELETE',
                // }).then(({json}) => ({data: json}))
            },

            deleteMany: (resource, params) => {
                // const query = {
                //     filter: JSON.stringify({ id: params.ids}),
                // };
                // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
                //     method: 'DELETE',
                //     body: JSON.stringify(params.data),
                // }).then(({ json }) => ({ data: json }));
            },
            activate: this.activate.bind(this),
            deactivate: this.activate.bind(this),
            restartFanout: (resource, params) => {
                // console.log('getOne', resource, params);
                return this.restartFanout(params);
            },
        }
    }

    async getFirebaseCollectionValues(resource, params) {
        let resource2collection = {
            'events': getTenantId('calendar'),
            'posts': getTenantId('posts'),
            'subscription_types': 'subscription_type',
            'user_subscriptions': 'user_subscription',
            'users': 'users',
            'stats': 'stats_daily',
            'tips': 'tickets',
            'tickets': 'tickets',
            'tenants': 'tenants',
        }
        console.log('About to return Firestore provider', documentId())

        console.log('getList', resource, params);
        const { page, perPage } = params.pagination || {};
        let { direction, lastPage } = /*params.meta||*/{};
        let { field: sort_field, order } = params.sort || {};
        const filter = params.filter || null;
        let i = 0;
        let wheres = [];
        if (resource === 'tips') {
            wheres[i++] = {
                fieldPath: 'type',
                optStr: '==',
                value: 'tip'
            }
        } else if (resource === 'tickets') {
            wheres[i++] = {
                fieldPath: 'type',
                optStr: 'in',
                value: ['event-ticket', 'stream-ticket', 'guest-ticket']
            }
        } else if (resource === 'tenants') {
            sort_field = 'name';
            order = 'asc';
        } else if (resource === 'events' || resource === 'posts') {
            let { state } = filter || {};
            if (!state) {
                state = resource === 'events' ? 'upcoming' : 'ended';
            }
            switch (state) {
                case 'upcoming':
                    order = 'asc';
                    wheres[i++] = {
                        fieldPath: 'status',
                        optStr: 'in',
                        value: ['scheduled', 'started', 'live', "cancelled"]
                    }
                    wheres[i++] = {
                        fieldPath: 'startDate',
                        optStr: '>',
                        value: moment().subtract(page && page === 1 ? 2 : 0, 'days').add(page - 1, filter.time).valueOf()
                    }
                    wheres[i++] = {
                        fieldPath: 'startDate',
                        optStr: '<',
                        value: moment().add(page, filter.time).valueOf()
                    }
                    break;
                case 'ended':
                    order = 'desc';
                    wheres[i++] = {
                        fieldPath: 'status',
                        optStr: 'in',
                        value: ['ended', 'published', 'started', 'live', 'cancelled']
                    }
                    wheres[i++] = {
                        fieldPath: 'startDate',
                        optStr: '>',
                        value: moment().subtract(page, filter.time).valueOf()
                    }
                    if (page > 1) {
                        wheres[i++] = {
                            fieldPath: 'startDate',
                            optStr: '<',
                            value: moment().subtract(page - 1, filter.time).valueOf()
                        }
                    }
                    break;
                case 'canceled':
                case 'cancelled':
                    order = 'desc';
                    wheres[i++] = {
                        fieldPath: 'status',
                        optStr: 'in',
                        value: ['cancelled']
                    }
                    break;
                case 'any':
                default:
                    order = 'desc';
                    break;
            }
        } else if (resource === 'users') {
            sort_field = 'username';
            order = 'asc';
            let { privilege } = filter || {};
            switch (privilege) {
                case 'administrator':
                case 'ambassador':
                case 'premium':
                    order = 'asc';
                    wheres[i++] = {
                        fieldPath: privilege,
                        optStr: '==',
                        value: true
                    }
                    break;
                default:
                    order = 'desc';
                    break;
            }
        }

        console.log('filter', page, perPage, sort_field, order.toLowerCase(), lastPage)

        const collection = `/${resource2collection[resource]}`;
        const orderBy = sort_field && sort_field !== 'id' ? {
            fieldPath: sort_field,
            directionStr: order.toLowerCase() || 'asc'
        } : null;
        const limit = 1000;
        console.log("About to get data from:", { collection, wheres, orderBy, limit });

        // return this.getAllFiltered(collection, where, orderBy, limit);
        let fparams = {
            collection,
            where: wheres[0] || null,
            where2: wheres[1] || null,
            where3: wheres[2] || null,
            orderBy,
            limit,
            startAt: (direction === 'next') ? lastPage : undefined,
            endAt: (direction === 'prev') ? lastPage : undefined
        };
        return this.getAllSearchObject(fparams).then((result) => {
            // console.log(result)
            let data = result && result.data ? result.data.map(item => {
                if (item.valid_from && item.valid_from.seconds)
                    item.valid_from = item.valid_from.seconds * 1000;
                if (item.valid_to && item.valid_to.seconds)
                    item.valid_to = item.valid_to.seconds * 1000;
                if (item.last_payment_date && item.last_payment_date.seconds)
                    item.last_payment_date = item.last_payment_date.seconds * 1000;
                if (item.next_payment_due_date && item.next_payment_due_date.seconds)
                    item.next_payment_due_date = item.next_payment_due_date.seconds * 1000;
                return item;
            }) : null;
            if (data && data.length) {
                return data;
            } else {
                // doc.data() will be undefined in this case
                console.warn("No such document!");
                return [];
            }
        }).catch((e) => {
            console.error('Call to getList failed [%s]', e.message || 'unknown', resource, params, e);
            return { error: `Call to getList failed ${e.message}`, data: [], total: 0 };
        });
    }

    logIn(value) {
        if (!firebase.auth().currentUser) {
            let provider = value;
            if (provider === 'google') {
                provider = new firebase.auth.GoogleAuthProvider();
            } else if (provider === 'microsoft') {
                provider = new firebase.auth.OAuthProvider('microsoft.com');
            } else if (provider === 'apple') {
                provider = new firebase.auth.OAuthProvider('apple.com')
            } else {
                provider = new firebase.auth.FacebookAuthProvider();
            }

            return firebase.auth().signInWithPopup(provider).then(function (result) {
                // var token = result.credential.accessToken;
                // var user = result.user;
                console.log('login');
            });
        } else {
            //firebase.auth().signOut();
            return Promise.resolve();
        }
    }

    /**
     * Start account pairing
     * @param err Error returned by signInWithPopup
     * @description
     * At this point, you should let the user know that they already have an \
     * account with a different provider, and validate they want to sign in
     * with the new provider.
     * Note: Browsers usually block popups triggered asynchronously, so in
     * real app, you should ask the user to click on a "Continue" button
     * that will trigger signInWithPopup().
     */
    pairAccounts(err) {
        if (err) {
            // User's email already exists.
            console.log('About to start pairing', err)
            // The pending credential.
            let pendingCred = err.credential;
            // The provider account's email address.
            let email = err.email;
            console.log('-++++++++++++++++', pendingCred, email)
            // Get the sign-in methods for this email.
            return firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
                console.log('++++++++++++++++', methods[0], methods)
                // If the user has several sign-in methods, the first method
                // in the list will be the "recommended" method to use.
                let provider;
                switch (methods[0]) {
                    case 'google.com':
                        provider = new firebase.auth.GoogleAuthProvider();
                        break;
                    case 'microsoft.com':
                        provider = new firebase.auth.OAuthProvider('microsoft.com');
                        break;
                    case 'apple.com':
                        provider = new firebase.auth.OAuthProvider('apple.com')
                        break;
                    case 'facebook.com':
                        provider = new firebase.auth.FacebookAuthProvider();
                        break;

                    case 'password': {
                        // TODO: Ask the user for their password.
                        // In real scenario, you should handle this asynchronously.
                        // var password = promptUserForPassword();
                        // firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
                        //     return result.user.linkWithCredential(pendingCred);
                        // }).then(() => {
                        //     // Facebook account successfully linked to the existing user.
                        //     goToApp();
                        // });
                        // return;
                        break;
                    }
                    default:
                        throw (new Error('Unknown auth provider'));
                }
                return firebase.auth().signInWithPopup(provider).then(result => {
                    // Note: Identity Platform doesn't control the provider's sign-in
                    // flow, so it's possible for the user to sign in with an account
                    // with a different email from the first one.

                    // Link the Facebook credential. We have access to the pending
                    // credential, so we can directly call the link method.
                    result.user.linkWithCredential(pendingCred).then(usercred => {
                        // Success.
                        // goToApp();
                    });
                });
            });
        } else {
            console.error('Could not pair accounts. Missing pending account');
            firebase.auth().signOut();
            throw (new Error('Could not pair accounts. Missing pending account'));
        }
    }

    logOut() {
        let logOut = new Promise((resolve, reject) => {
            try {
                return firebase.auth().signOut().then(resolve);
            } catch (e) {
                console.log('FIREBASE ERROR', e);
                reject(e.message);
            }
        })/*.catch((reason) => {
            // Log the rejection reason
            console.error('Log out error: ' + reason);
            resolve(null);
        });*/

        return logOut;
    }

    init() {
        let appInit = new Promise((resolve, reject) => {
            try {
                if (firebase.app().name) {
                    firebase.auth().onAuthStateChanged((user) => {
                        if (user && user.uid) {
                            let { uid, displayName, email, photoURL } = user;
                            // console.log('User:', { uid, displayName, email, photoURL });
                            this.getUserData({ uid, displayName, email, photoURL }, true).then(() => {
                                resolve(true);
                            });
                            // this.getUserPublicData();
                        } else {
                            this._store.dispatch(firebaseActions.setUser({ auth: false, user: null, userLoaded: true }));
                            resolve(true);
                        }
                    });
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Aplication error: ' + reason);
            return Promise.resolve(null);
        });

        return appInit;

    }


    getOne(collection, id) {
        let readObjectRef = firebase.functions().httpsCallable('readObject');
        return readObjectRef({ collection, id }).then((result) => {
            console.log("getOne:", result, id);
            let resultJson = Object.assign({}, result.data, { id: id });

            if (collection && collection === 'users' && resultJson && resultJson.tenants && resultJson.tenants.length) {
                resultJson.tenants = resultJson.tenants.map((item) => item.id);
            }

            return { data: resultJson };
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getAllFiltered(collection, where, orderBy, limit = 50, startAt = null) {
        let category = new Promise((resolve, reject) => {
            try {
                // console.log("About to get filtered data from:", { collection, where, orderBy, limit, startAt });
                let readObjectRef = firebase.functions().httpsCallable('readFilteredObjects');
                return readObjectRef({ collection, where, orderBy, limit, startAt }).then((result) => {
                    // console.log('Filtered result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Category', e);
                reject(e.message);
            }
        });
        return category;
    }

    getAllSearchEvents(resource, filter_by) {
        let objects = new Promise((resolve, reject) => {
            try {
                // console.log("About to get search data from:", { collection, where, where2, where3, orderBy, limit, startAfter });
                let readObjectRef = firebase.functions().httpsCallable('searchEventsFS');
                return readObjectRef({ search_by: filter_by }).then((result) => {
                    let filtered = [], type;
                    if (resource === 'events')
                        type = 'event';
                    else if (resource === 'posts')
                        type = 'stream';
                    if (result && result.data) {
                        filtered = result.data.filter((item) => item.type === type)
                    }
                    console.log('Search result received', result, filtered)//, result);
                    let got = filtered && filtered.length ? {
                        data: filtered,
                        total: filtered.length || 0,
                    } : { data: [], total: 0 }
                    resolve(got);
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    getAllSearchPosts(filter_by) {
        let objects = new Promise((resolve, reject) => {
            try {
                // console.log("About to get search data from:", { collection, where, where2, where3, orderBy, limit, startAfter });
                let readObjectRef = firebase.functions().httpsCallable('searchEventsFS');
                return readObjectRef({ filter_by }).then((result) => {
                    // console.log('Search result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    getAllSearchObject(params) {
        let { collection, where, where2, where3, orderBy, limit, startAfter, endBefore, startAt, endAt } = params;
        let should_reverse = endBefore || endAt;
        if (!limit)
            limit = 1000;
        if (should_reverse) {
            if (orderBy.directionStr === 'asc') {
                orderBy.directionStr = 'desc';
            } else {
                orderBy.directionStr = 'asc';
            }
            if (endAt) {
                startAt = endAt;
                endAt = undefined;
            }
            else if (startAt) {
                endAt = startAt;
                startAt = undefined;
            }
            if (endBefore) {
                startAfter = endBefore;
                endBefore = undefined;
            }
            else if (startAfter) {
                endBefore = startAfter;
                startAfter = undefined;
            }
        }

        let res = new Promise((resolve, reject) => {
            try {
                console.log("About to get search data from:", { collection, where, where2, where3, orderBy, limit, startAfter, endBefore, startAt, endAt, should_reverse });
                let readObjectRef = firebase.functions().httpsCallable('readSearchObjects1');
                return readObjectRef({ collection, where, where2, where3, orderBy, limit, startAfter, endBefore, startAt, endAt }).then((result) => {
                    console.log('Search result received', result);
                    if (should_reverse && result && result.data && result.data.reverse) {
                        result.data = result.data.reverse();
                    }
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return res;
    }

    add(collection, data, id) {
        //console.log("About to add data to:", { collection });
        // return null;
        let createObjectRef = firebase.functions().httpsCallable('createObject');
        return createObjectRef({ collection, data, id }).then((result) => {
            console.log("add RESULT:", result);
            let id = result.data;
            return this.getOne('/tenants', id);
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    update(collection, data, id) {
        // console.log("About to update data to:", { collection, data, id });
        // return null;
        let opRef = firebase.functions().httpsCallable('updateObject');
        return opRef({ collection, data, id }).then((result) => {
            // console.log("update RESULT:", result);
            return result.data;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    delete(collection, id, subcollection = null) {
        // console.log("About to update data to:", { collection, id, subcollection });
        // return null;
        let opRef = firebase.functions().httpsCallable('deleteObject');
        return opRef({ collection, id, subcollection }).then((result) => {
            // console.log("delete RESULT:", result);
            return id;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    updateUser(uid, data) {
        let callableRef = firebase.functions().httpsCallable('updateUser');
        return callableRef({ userId: uid, userData: data }).then((result) => {
            return result.data;
        });
    }

    summarizeURL(mediaUrl) {
        let callableRef = firebase.functions().httpsCallable('summarizeURL');
        return callableRef({ url: mediaUrl }).then((result) => {
            return result.data;
        });
    }

    listSummaries() {
        let callableRef = firebase.functions().httpsCallable('listSummaries');
        return callableRef({}).then((result) => {
            if (result?.data?.data) {
                let summaries = result.data.data.sort((a, b) => {
                    return a.timestamp > b.timestamp ? -1 : 1;
                });
                return { data: summaries, total: summaries.length };
            } else {
                return { data: [], total: 0 };
            }
        });
    }

    getSummary(summaryId) {
        let callableRef = firebase.functions().httpsCallable('getSummary');
        return callableRef({ id: summaryId }).then((result) => {
            return result.data;
        });
    }

    crudRT(type, collection, data) {
        // console.log("About to update data to:", { collection, data, id });
        // return null;
        let opRef = firebase.functions().httpsCallable('crudRT');
        return opRef({ type, collection, data }).then((result) => {
            // console.log("update RESULT:", result);
            return result.data;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getUserPublicData() {
        this._store.dispatch(firebaseActions.setPublicData({ loadedPublicData: false }));
        let getUserPublicDataRef = firebase.functions().httpsCallable('getUserPublicData');
        return getUserPublicDataRef().then((result) => {
            this._store.dispatch(firebaseActions.setPublicData({ ...result.data, loadedPublicData: true }));
            return result.data;
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getUserData(user, auth) {
        if (!user)
            return Promise.resolve(null);
        const userDetails = {
            uid: user.uid ? user.uid : null,
            displayName: user.displayName ? user.displayName : null,
            photoURL: user.photoURL ? user.photoURL : null,
            email: user.email ? user.email : null
        }
        if (!user.uid) {
            // Should not be here
            console.warn('User without uid found!', user)
            this._store.dispatch(firebaseActions.setUser({ user: userDetails, auth: auth, userLoaded: true }));
            return Promise.resolve(userDetails);
        }
        let rq = firebase.functions().httpsCallable('getUserData');
        return rq(user).then((doc) => {
            // console.log('getUserData response:', doc)
            if (!doc)
                return null;

            let udata = doc.data;

            if (udata && udata.user) {
                let combined = Object.assign({}, userDetails, udata.user);
                this._store.dispatch(firebaseActions.setUser({ user: combined, auth: auth, userLoaded: true }));
                return combined;
            }
            return null;
        }).catch(e => {
            console.error('Could not load user data', e.message);
        });
    }

    saveCalendarEvent(data) {
        let callableRef = firebase.functions().httpsCallable('saveCalendarEvent');
        return callableRef(data).then((result) => {
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function saveCalendarEventFS', error);
        });
    }

    saveSubscriptionType(data) {
        // Get a reference to the service
        const db = firebase.firestore();
        let colRef = db.collection('subscription_type');
        let docRef = colRef.where("type", "==", data.type).orderBy('version', 'desc');
        const { active } = data;

        return docRef.get().then(async (documentSnapshots) => {
            // console.log("Document documentSnapshots:", documentSnapshots);
            // Get all versions of the requested type
            let stypes = documentSnapshots.docs.map(doc => Object.assign(doc.data(), { id: doc.id })); // Add ids to returned docs
            console.log("Document stypes:", stypes);
            if (stypes && stypes.length) {
                console.log('S.Type: ', stypes[0]);
                data.version = stypes[0].version + 1;
            } else {
                data.version = 1;
            }
            data.active = false;
            // Add a new document with a generated id.
            return colRef.add(data)
                .then(async (docRef) => {
                    console.log("Document written with ID: ", docRef.id);
                    if (active) {
                        let p = [];
                        if (stypes && stypes.length) {
                            stypes.forEach(type => {
                                if (type.active && docRef.id !== type.id) {
                                    p.push(colRef.doc(type.id).set({ active: false }, { merge: true }))
                                }
                            });
                        }
                        p.push(colRef.doc(docRef.id).set({ active: true }, { merge: true }));
                        await Promise.allSettled(p);
                    }
                    return { data: Object.assign(data, { id: docRef.id }) }
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                    return Promise.reject();
                });
        }).catch((e) => {
            console.error('Call to getList failed [%s]', e.message || 'unknown', data, e);
            return Promise.reject();
        });

    }

    async saveUserSubscription(data, previousData) {
        // Get a reference to the service
        const db = firebase.firestore();
        const rtdb = firebase.database();
        console.log('saveUserSubscription data', data)
        // Promise.reject('cc');
        const { user_id, subscription_id, valid_from, valid_to, id, hosts,
            last_payment_date, next_payment_due_date } = data || {};
        if (!user_id || !subscription_id || !valid_from || !valid_to)
            return Promise.reject('Data is missing');
        let user = await db.collection('users').doc(user_id).get()
            .then(async (documentSnapshots) => {
                // Get the last visible
                if (documentSnapshots.exists) {
                    console.log("Document user data:", documentSnapshots.data());
                    let data = documentSnapshots.data();

                    return { ...data, id: documentSnapshots.id };
                } else {
                    // doc.data() will be undefined in this case
                    console.warn("No such user!");
                    return Promise.reject("No such user!");
                }
            }).catch((e) => {
                console.error('Could not get user data [%s]', e.message || 'unknown', e);
                return Promise.reject(e.message || 'Unknown error');
            });
        let subscription = await db.collection('subscription_type').doc(subscription_id).get()
            .then(async (documentSnapshots) => {
                // Get the last visible
                if (documentSnapshots.exists) {
                    console.log("Document subscription data:", documentSnapshots.data());
                    let data = documentSnapshots.data();
                    return { ...data, id: documentSnapshots.id };
                } else {
                    // doc.data() will be undefined in this case
                    console.warn("No such subscription!");
                    return Promise.reject("No such subscription!");
                }
            }).catch((e) => {
                console.error('Could not get subscription_type [%s]', e.message || 'unknown', e);
                return Promise.reject(e.message || 'Unknown error');
            });
        try {
            var valid_from_date = !Number.isInteger(valid_from) ? new Date(valid_from + ' 00:00:00') : new Date(valid_from);
            var valid_to_date = !Number.isInteger(valid_to) ? new Date(valid_to + ' 23:59:59') : new Date(valid_to);
            var last_payment_date2 = !last_payment_date ?
                null : ((typeof last_payment_date == 'string')
                    ? new Date(last_payment_date + ' 00:00:00')
                    : (last_payment_date.seconds ? new Date(last_payment_date.seconds * 1000) : new Date(last_payment_date)));
            var next_payment_due_date2 = !next_payment_due_date ?
                null : ((typeof next_payment_due_date == 'string')
                    ? new Date(next_payment_due_date + ' 23:59:59')
                    : (next_payment_due_date.seconds ? new Date(next_payment_due_date.seconds * 1000) : new Date(next_payment_due_date)));
        } catch (e) {
            console.error('Not a valid date [%s]', e.message || 'unknown', e);
            return Promise.reject(`Not a valid date ${e.message || 'unknown'}`);
        }
        console.log('Preparing data user, subscription, valid_from_date, valid_to_date, last_payment_date2, next_payment_due_date2', user, subscription, valid_from_date, valid_to_date, last_payment_date2, next_payment_due_date2);
        // Prepare save data
        const ndata = !id ? {
            ...subscription,
            user_name: `${user.username} <${user.email}>`,
            user_id: user.id,
            companyName: user.companyName || "",
            email: user.email || "",
            phoneNumber: user.phoneNumber || "",
            userTitle: user.title || "",
            subscription_id: subscription.id,
            subscription_name: `${subscription.title}, v${subscription.version}`,
            status: 'pending',
            hosts: hosts || [],
            valid_from: valid_from_date,
            valid_to: valid_to_date,
            last_payment_date: last_payment_date2,
            next_payment_due_date: next_payment_due_date2,
            used: 0,
            available_amount: (subscription.max_event_hours || 0) * 60 * 60 * 1000
        } : {
            ...data,
            user_name: data.user_name || `${user.username} <${user.email}>`,
            user_id: user.id,
            subscription_id: subscription.id,
            subscription_name: data.subscription_name || `${subscription.title}, v${subscription.version}`,
            hosts: hosts || [],
            valid_from: valid_from_date,
            valid_to: valid_to_date,
            last_payment_date: last_payment_date2,
            next_payment_due_date: next_payment_due_date2,
            // used:0,
            // available_amount: (data.max_event_hours||0)*60*60*1000
        }
        let should_reset_balance = false;
        if (previousData && (
            previousData.status === 'pending' || previousData.status === 'requested'
        )) {
            console.log('Should be safe to update available_amount');
            ndata.available_amount = (data.max_event_hours || 0) * 60 * 60 * 1000;
            if (data.status === 'active') {
                should_reset_balance = true;
            }
        }
        console.log('Data to be saved:', ndata);
        let subscriptionName = ndata.subscription_name;

        return Promise.resolve(id)
            .then(async (id) => {
                if (id)
                    return db.collection('user_subscription').doc(id).set(ndata)
                        .then(async () => {
                            console.log("Document written: ", ndata.id, ndata);
                            return { data: ndata }
                        });
                else
                    return db.collection('user_subscription').add(ndata)
                        .then(async (docRef) => {
                            console.log("Document written with ID: ", docRef.id);
                            return { data: Object.assign(ndata, { id: docRef.id }) }
                        });
            })
            .then(async (result) => {
                if (result && result.data &&
                    (result.data.status !== 'suspended')
                ) {
                    // Set RT data for billing
                    const rtdata = { ...result.data };
                    rtdata.valid_from = !valid_from_date ? valid_from_date : valid_from_date.getTime();
                    rtdata.valid_to = !valid_to_date ? valid_to_date : valid_to_date.getTime();
                    rtdata.last_payment_date = !last_payment_date2 ? null : rtdata.last_payment_date.getTime();
                    rtdata.next_payment_due_date = !next_payment_due_date2 ? null : rtdata.next_payment_due_date.getTime();
                    console.log('---------------Saving rtdata', rtdata);
                    // Suspend all other active user subscriptions
                    let colRef = db.collection('user_subscription');
                    let docRef = colRef.where("user_id", "==", data.user_id);
                    await docRef.get().then(async (documentSnapshots) => {
                        if (!documentSnapshots || !documentSnapshots.docs || !documentSnapshots.docs.length)
                            return;
                        // console.log("Document documentSnapshots:", documentSnapshots);
                        // Get all Active user subscriptions and suspend them
                        let usubs = documentSnapshots.docs.map(doc => Object.assign(doc.data(), { id: doc.id })); // Add ids to returned docs
                        console.log("Document stypes:", usubs);

                        let p = [];
                        if (usubs && usubs.length) {
                            usubs.forEach((subs, index) => {
                                if (subs.status === 'active' && result.data.id !== subs.id) {
                                    p.push(colRef.doc(subs.id).set({ status: 'suspended' }, { merge: true }));
                                } else if (result.data.id === subs.id) {
                                    usubs[index].status = 'active';
                                    p.push(colRef.doc(subs.id).set({ status: 'active' }, { merge: true }));
                                }
                            });
                        }
                        console.log('About to update rtdb', result.data);
                        p.push(rtdb.ref('protected/user/' + result.data.user_id).set(rtdata));
                        if (should_reset_balance) {
                            p.push(rtdb.ref('/billing/subscription/' + result.data.user_id).set(rtdata));
                            p.push(db.collection(`/user_subscription/${id}/history`).add({
                                ...rtdata,
                                timestamp: Date.now(),
                                action: 'balance set',
                            }));
                        }
                        // let active = usubs.find(item => item.status=='active');
                        // if(active) {
                        //     // Set active subscription to rtdb
                        // } else {
                        //     // delete from rtdb
                        //     p.push(rtdb.ref('protected/user/' + result.data.user_id).set(null));
                        // }
                        p.push(this.saveUser({
                            id: result.data.user_id,
                            eventAdmin: true
                        }));
                        return Promise.allSettled(p);
                    });
                } else {
                    // ...Delete from rt db if it was active
                    let found = await rtdb.ref('protected/user/' + result.data.user_id).get().then((ds) => {
                        if (!ds.exists())
                            return null;
                        return ds.val();
                    });
                    console.log('About to update rtdb 2', found, result.data);
                    if (found && /*found.status == 'active' &&*/ found.id === result.data.id) {
                        // delete from rtdb
                        await rtdb.ref('protected/user/' + result.data.user_id).set(null);
                    }
                }
                return result;
            })
            .then(async (result) => {
                if (result && result.data &&
                    (result.data.status === 'active' || result.data.status === 'suspended')
                ) {
                    let type = 'update';
                    if (result.data.status === 'suspended')
                        type = 'suspend';
                    // Notify user
                    let sdata = { type, userId: result.data.user_id, subscriptionName };
                    sendSubscriptionMail(sdata)
                        .catch((error) => {
                            console.error('Could not send subscription email', sdata);
                        })
                }
                return result;
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return Promise.reject(`Could not save user subscription: ${error.message || 'Unknown error'}`);
            });

    }

    async saveUser(data, previousData) {
        // Get a reference to the service
        console.log('saveUser data', data, previousData);
        const { id } = data;

        if (data && data.tenants && data.tenants.length && JSON.stringify(data.tenants) !== JSON.stringify(previousData.tenants)) {
            let customTenants = await this.getAllSearchObject({
                collection: `/tenants`,
                where: {
                    fieldPath: 'active',
                    optStr: '==',
                    value: true
                }
            }).then((doc) => ((doc && doc.data) || []))
                .catch(e => {
                    console.error(e);
                    return [];
                });

            // eslint-disable-next-line 
            data.tenants = await customTenants.filter((item) => {
                if (data.tenants.indexOf(item.id) !== -1) {
                    return item;
                }
            });
        }

        return Promise.resolve(id)
            .then(async (id) => {
                if (id)
                    return this.updateUser(id, data)
                        .then(async (res) => {
                            console.log("Document written: ", id, res);
                            return this.getOne('users', id)
                                .then((res) => {
                                    if (res.data && res.data.id) {
                                        console.log("Document data:", res.data);
                                        return { data: res.data };
                                    } else {
                                        // doc.data() will be undefined in this case
                                        console.warn(`Could not find user ${id}`);
                                        return Promise.reject(`Could not find user ${id}`);
                                    }
                                });
                        });
                else
                    return Promise.reject(`Could not find user ${id}`);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return Promise.reject(`Could not save user: ${error.message || 'Unknown error'}`);
            });

    }

    async saveTenant(data) {
        try {
            // Get a reference to the service
            console.log('saveTenant data', data);
            // Get a reference to the service
            const db = firebase.firestore();
            // eslint-disable-next-line 
            let colRef = db.collection('tenants');
            // let docRef = colRef.where("type", "==", data.type).orderBy('version', 'desc');
            // eslint-disable-next-line 
            const { active, name, id } = data;

            if (id) {
                // Save existing
                return this.update('/tenants', data, data.id);
            } else {
                return this.add('/tenants', data);
                // Add a new document with a generated id.
            }

        } catch (e) {
            console.error('Could not add tenant [%s]', e.message || 'unknown', data, e);
            return Promise.reject();
        };

    }

    activate(resource, data) {
        console.log('activate data', resource, data)
        // Get a reference to the service
        const db = firebase.firestore();

        switch (resource) {
            case 'subscription_types': {
                let colRef = db.collection('subscription_type');
                const { active, type, id } = data.data || {};
                let docRef = colRef.where("type", "==", type).orderBy('version', 'desc');

                return docRef.get().then(async (documentSnapshots) => {
                    // console.log("Document documentSnapshots:", documentSnapshots);
                    // Get all versions of the requested type
                    let stypes = documentSnapshots.docs.map(doc => Object.assign(doc.data(), { id: doc.id })); // Add ids to returned docs
                    console.log("Document stypes:", stypes);
                    let p = [];
                    if (stypes && stypes.length) {
                        stypes.forEach(type => {
                            if (active && type.active && id !== type.id) {
                                p.push(colRef.doc(type.id).set({ active: false }, { merge: true }));
                            } else if (id === type.id) {
                                p.push(colRef.doc(type.id).set({ active }, { merge: true }));
                            }
                        });
                    }
                    // p.push(colRef.doc(id).update({active: true}, {merge: true}));
                    await Promise.allSettled(p);
                    return colRef.doc(id).get()
                        .then(docSnapshots => {
                            if (docSnapshots.exists) {
                                console.log("Document data:", docSnapshots.data());
                                let data = docSnapshots.data();
                                return { data: { ...data, id: docSnapshots.id } };
                            } else {
                                // doc.data() will be undefined in this case
                                console.warn(`Could not find ${resource} ${id}`);
                                return Promise.reject(`Could not find ${resource} ${id}`);
                            }
                        });
                }).catch((e) => {
                    console.error('Call to getList failed [%s]', e.message || 'unknown', data, e);
                    return Promise.reject(`Call to getList failed ${e.message || 'unknown'}`);
                });
            }
            default: { }
        }

    }


    getDynamicLink(eventId, type) {
        let linkRef = firebase.functions().httpsCallable('getDynamicLink');
        return linkRef({ eventId, type }).then((result) => {
            return result.data;
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    handleImageUpload(imageFile, maxWidthOrHeight = null) {
        let img = new Promise((resolve, reject) => {
            let size = imageFile.size / 1024 / 1024;
            // console.log('originalFile', imageFile); // true
            // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            // console.log(`originalFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`);

            if (size > 1 || maxWidthOrHeight) {
                var options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: maxWidthOrHeight ? maxWidthOrHeight : 1920,
                    useWebWorker: true
                }
                imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        // console.log('compressedFile', compressedFile); // true
                        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                        // console.log(`compressedFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`); // smaller than maxSizeMB

                        resolve(compressedFile); // write your own logic
                    })
                    .catch(function (error) {
                        reject(error.message);
                    });
            } else {
                resolve(imageFile)
            }
        })
        return img;
    }

    addImageToStorage(file, type, id) {
        let storageAdd = new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();

            this.handleImageUpload(file).then((resizeImg) => {
                if (resizeImg) {
                    // Create the file metadata
                    var metadata = {
                        contentType: resizeImg && resizeImg.type ? resizeImg.type : 'image/jpeg'
                    };

                    let folder = 'profile_pictures/';

                    if (type && type === 'event') {
                        folder = 'events_pictures/'
                    }
                    // Upload file and metadata to the object 'images/mountains.jpg'
                    var uploadTask = storageRef.child(folder + id).put(resizeImg, metadata);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function (snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            // console.log('Upload is ' + progress + '% done');
                            // switch (snapshot.state) {
                            //     case firebase.storage.TaskState.PAUSED: // or 'paused'
                            //         // console.log('Upload is paused');
                            //         break;
                            //     case firebase.storage.TaskState.RUNNING: // or 'running'
                            //         // console.log('Upload is running');
                            //         break;
                            // }
                        }, (error) => {
                            reject(error)
                            // switch (error.code) {
                            //     case 'storage/unauthorized':
                            //         // User doesn't have permission to access the object
                            //         break;

                            //     case 'storage/canceled':
                            //         // User canceled the upload
                            //         break;

                            //     case 'storage/unknown':
                            //         // Unknown error occurred, inspect error.serverResponse
                            //         break;
                            // }
                        }, function () {
                            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                // console.log('File available at', downloadURL);
                                return resolve(downloadURL);
                            });
                        });
                }
            });
        })
        return storageAdd;
    }

    getEvent(eventId) {
        let event = new Promise((resolve, reject) => {

            const safeParse = (json) => {
                let ret = null;
                try {
                    ret = JSON.parse(json);
                } catch (e) {
                    console.error('Could not parse data', json)
                }
                return ret;
            }

            const loadEventData = (snapshot) => {
                if (snapshot.exists()) {
                    // console.log('----------------------- Cached data:', snapshot.val());
                    let cached = safeParse(snapshot.val()), event = null;

                    if (cached && cached.length) {
                        cached.filter((item) => {
                            if (item.id === eventId)
                                event = item;
                            return item;
                        });
                    }

                    return event;
                } else {
                    console.log("No cached public data available");
                    return null;
                }
            };

            firebase.database().ref('public/events').get()
                .then(loadEventData)
                .then(async (result) => {
                    if (result !== null) {
                        // console.log('Return RT', result);
                        resolve(result);
                    } else {
                        const event = await this.readObject(getTenantId('calendar'), eventId);
                        if (event && event.data) {
                            // console.log('Return FS', event.data);
                            resolve(event.data);
                        }
                    }
                });
        });

        return event;
    }

    getPost(postId) {
        let event = new Promise((resolve, reject) => {

            const safeParse = (json) => {
                let ret = null;
                try {
                    ret = JSON.parse(json);
                } catch (e) {
                    console.error('Could not parse data', json)
                }
                return ret;
            }

            const loadPostData = (snapshot) => {
                if (snapshot.exists()) {
                    // console.log('----------------------- Cached data:', snapshot.val());
                    let cached = safeParse(snapshot.val()), event = null;

                    if (cached && cached.length) {
                        cached.filter((item) => {
                            if (item.id === postId)
                                event = item;
                            return item;
                        });
                    }

                    return event;
                } else {
                    console.log("No cached public data available");
                    return null;
                }
            };

            firebase.database().ref('public/events').get()
                .then(loadPostData)
                .then(async (result) => {
                    if (result !== null) {
                        // console.log('Return RT', result);
                        resolve(result);
                    } else {
                        const event = await this.readObject(getTenantId('calendar'), postId);
                        if (event && event.data) {
                            // console.log('Return FS', event.data);
                            resolve(event.data);
                        }
                    }
                });
        });

        return event;
    }


    async findUsersFS(resource, searchBy) {
        console.log('About to search users by', searchBy)
        function stringToBytes(str) {
            var ch, st, re = [];
            for (var i = 0; i < str.length; i++) {
                ch = str.charCodeAt(i);  // get char
                st = [];                 // set up "stack"
                do {
                    st.push(ch & 0xFF);  // push byte to stack
                    ch = ch >> 8;          // shift value down by 1 byte
                }
                while (ch);
                // add stack contents to result
                // done because chars have "wrong" endianness
                re = re.concat(st.reverse());
            }
            // return an array of bytes
            return re;
        }

        if (searchBy && searchBy.trim && searchBy.trim()) {
            let byteString = '1', text = searchBy, start = 0, end = 0;
            text = text.substring(0, 4);
            text = text.toLowerCase();
            let byteArray = stringToBytes(text);
            byteArray.map((item) => {
                if (item < 100) {
                    byteString = byteString.concat(`0${item}`);
                } else {
                    byteString = byteString.concat(item);
                }
                return null;
            })
            if (byteArray && byteArray.length === 3) {
                start = byteString.concat('0000');
                end = byteString.concat('9999');
            } else {
                start = byteString.concat('0');
                end = byteString.concat('9');
            }
            start = parseInt(start);
            end = parseInt(end);

            const where = {
                fieldPath: 'sortId',
                optStr: '<',
                value: end ? end : 0
            }

            const where2 = null;/*{
                fieldPath: 'broadcaster',
                optStr: '==',
                value: true
            }*/

            const orderBy = {
                fieldPath: 'sortId'
            }
            const limit = 500;
            const startAfter = start ? start : null;
            // console.log("About to get data from:", { collection, where, orderBy, limit });

            return this.getAllSearchUsers(where, where2, null, orderBy, limit, startAfter).then((result) => {
                if (result && result.data) {
                    let users = result.data;
                    let filtered = users.filter((item) => {
                        let username = item.username.toLowerCase();
                        let searchValue = searchBy.toLowerCase();
                        if (username.indexOf(searchValue) !== -1) {
                            return item;
                        } else return null;
                    })
                    return { data: filtered, total: filtered.length };
                } else {
                    return { data: [], total: 0 };
                }
            });
        } else {
            return { data: [], total: 0 };
        }
    }

    getAllSearchUsers(where, where2, where3, orderBy, limit = 200, startAfter = null) {
        let objects = new Promise((resolve, reject) => {
            try {
                let collection = 'users';
                let readObjectRef = firebase.functions().httpsCallable('readSearchObjects');
                return readObjectRef({ collection, where, where2, where3, orderBy, limit, startAfter }).then((result) => {
                    // console.log('Search result received')//, result);
                    let filtered = null;
                    if (result && result.data) {
                        filtered = result.data;//.filter((item) => item.type == type)
                    }
                    console.log('Search result received', result, filtered)//, result);
                    let got = filtered && filtered.length ? {
                        data: filtered,
                        total: filtered.length || 0,
                    } : { data: [], total: 0 }
                    resolve(got);
                }).catch((error) => {
                    reject(error);
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    readObject(collection, id) {
        let readObjectRef = firebase.functions().httpsCallable('readObject');
        return readObjectRef({ collection, id }).then((result) => {
            console.log("RESULT:", result, id);
            const resultJson = Object.assign({}, result.data, { id: id });
            return { data: resultJson };
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getVideoLinks(alias, administrator = false, newRecording = false) {
        let ref = firebase.functions().httpsCallable('getVideoLinks');
        return ref({
            alias,
            administrator,
            tenantId: process.env.REACT_APP_tenantId || null,
            newRecording
        }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not get video links: ${e.message}`)
        });
    }

    generateVTTFile(eventId) {
        let ref = firebase.functions().httpsCallable('generateVTTFile');
        return ref({ eventId }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not generate VTT: ${e.message}`)
        });
    }

    getEventStats(alias) {
        let getEventStatsRef = firebase.functions().httpsCallable('getEventStats');
        return getEventStatsRef({ alias }).then((result) => {
            return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getStats() {
        //{"result":{"createdEvents":4187,"userCount":8146,"eventMinutes":155784.85000000012,"eventCount":2509,"activeCreatorsInAWeek":14}}
        // this._store.dispatch(firebaseActions.setPublicData({ loadedPublicData: false }));
        let getStatsRef = firebase.functions().httpsCallable('getStats');
        return getStatsRef({ key: "sentinel" }).then((result) => {
            console.log('getStats', result.data)
            // this._store.dispatch(firebaseActions.setPublicData({ ...result.data, loadedPublicData: true }));
            return { data: { id: undefined, ...result.data } };
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    async getRTData(collection) {
        const db = firebase.database();
        let docRef = db.ref(collection);

        return docRef.get().then(async (documentSnapshots) => {
            // console.log("Document documentSnapshots:", documentSnapshots);
            if (!documentSnapshots || !documentSnapshots.val || !documentSnapshots.exists()) {
                return null;
            }
            console.log('Got doc snapshots', documentSnapshots.val())
            let data = documentSnapshots.val();

            if (data) {
                console.log("Document data:", data);
                return data;
            } else {
                // doc.data() will be undefined in this case
                console.warn("No such document!");
                return null;
            }
        }).catch((e) => {
            console.error('Call to getList failed [%s]', e.message || 'unknown', e);
            return { error: `Call to getList failed ${e.message}` };
        })
    }

    async getFanouts(params) {
        let ref = firebase.functions().httpsCallable('getFanouts');
        return ref(params).then((result) => {
            if (!result || !result.data)
                return { data: [], total: 0 };
            let ret = result.data;
            console.log('fanouts', result, { data: ret, total: ret.length });
            return { data: ret, total: ret.length };
        }).catch(e => {
            console.error(`Could not get fanouts: ${e.message}`)
        });
    }

    async searchUsers(params) {
        let ref = firebase.functions().httpsCallable('searchUsersFS');
        return ref(params).then((result) => {
            if (!result || !result.data)
                return { data: [], total: 0 };
            let ret = result.data;
            console.log('searchUsers', result, { data: ret, total: ret.length });
            return { data: ret, total: ret.length };
        }).catch(e => {
            console.error(`Could not get searchUsers: ${e.message}`)
        });
    }

    async getFeaturedUsers(params) {
        let ref = firebase.functions().httpsCallable('getFeaturedUsers');
        return ref(params).then((result) => {
            if (!result || !result.data)
                return { data: [], total: 0 };
            let ret = result.data;
            console.log('getFeaturedUsers', { data: ret, total: ret.length });
            return { data: ret, total: ret.length };
        }).catch(e => {
            console.error(`Could not get getFeaturedUsers: ${e.message}`)
        });
    }

    getFanout(params) {
        let { id } = params;
        let ref = firebase.functions().httpsCallable('getFanout');
        return ref({ ip: id }).then((result) => {
            console.log('Got fanout', result, { data: { ...result.data, id } })
            return { data: { ...result.data, id } };
        }).catch(e => {
            console.error(`Could not get fanout ${id}: ${e.message}`)
        });
    }

    async restartFanout(params) {
        let { id } = params;
        let ref = firebase.functions().httpsCallable('restartFanout');
        return ref({ ip: id }).then((result) => {
            console.log('Got reset result', result, { data: { ...result.data, id } })
            return { data: { ...result.data, id } };
        }).catch(e => {
            console.error(`Could not reset fanout: ${e.message}`)
        });
        // const rtdb = firebase.database();
        // console.log('About to restart fanout', id);
        // return rtdb.ref(`/watchdog/instances/${id}/restart`).set(true);
    }


    attachRTListener(path, listener) {
        if (!path || !listener)
            return;
        //console.log('About to attach to', path);
        let pathRef = firebase.database().ref(path);
        //console.log('About to attach to', pathRef);
        //pathRef.once('value', listener);
        pathRef.on('value', listener);
    }

    detachRTListener(path, listener) {
        if (!path || !listener)
            return;
        //console.log('About to detach from', path);
        let pathRef = firebase.database().ref(path);
        pathRef.off('value', listener);
    }

    attachFSDocListener(collection, docId, listener) {

        const fs = firebase.firestore();
        if (!collection || !docId)
            return;
        const unsub = onSnapshot(doc(fs, collection, docId), listener);

        return unsub;
    }

    attachFSColListener(path, listener) {
        const fs = firebase.firestore();

        if (!path)
            return;

        const q = query(collection(fs, path)/*, where("state", "==", "CA")*/);
        const unsubscribe = onSnapshot(q, listener);

        // const unsub = onSnapshot(doc(fs, collection, docId), listener);

        return unsubscribe;
    }

    detachFSListener(callback) {
        if (!callback)
            return;
        //console.log('About to detach from', path);
        return callback();
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            // eslint-disable-next-line
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case 'firebase/setUser': {
                    let signIn = state.app.signIn;
                    if (signIn)
                        dispatch(appActions.showSignIn({ signIn: false }));
                    break;
                }
                default: {
                    break;
                }
            }
            return res;
        };
    }
}

const getObjects = async (snapshot, filter) => {
    if (!snapshot)
        return null;
    let value = snapshot.docs.map(doc => Object.assign(!filter ? doc.data() : filter(doc.data()), { id: doc.id })); // Add ids to returned docs
    return value;
}

const sendSubscriptionMail = (data) => {
    let subscriptionMailRef = firebase.functions().httpsCallable('subscriptionMail');
    return subscriptionMailRef(data).then((result) => {
        return Promise.resolve();
        // return result;
    }).catch((error) => {
        // var code = error.code;
        // var message = error.message;
        // var details = error.details;
        console.error('There was an error when calling the Cloud Function', error);
        return Promise.reject();
    });
}
// eslint-disable-next-line
export default new FirebaseClient();
