import * as React from "react";
import { useEffect, useState } from "react";
import {
    useRecordContext,
    useRedirect
} from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import moment from "moment/moment";
import { DataGrid } from "@mui/x-data-grid";
import get from 'lodash/get';
import {
    renderDuration,
    getTenantId
} from "../../../utils/HelperFunctions";
// import CopyToClipboardButton from '../../common/CopyToClippboardButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboardButton from "../../common/CopyToClippboardButton";

// eslint-disable-next-line
export default ({ source, fieldName }) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    console.log("RECORD", record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        // console.log('onDocUpdate', doc);
        // console.log("Got FS data: ", doc.data());
        if (doc && doc.data) {
            let filtered = doc.data.map((row) => {
                let date = null;
                if (row.startDate) {
                    const m = moment(row.startDate);
                    date = m.format("L") + ' ' + m.format("LTS");
                }
                const updatedRow = {
                    id: row.id,
                    startDate: row.startDate,
                    date,
                    author: row.author || row.authorId,
                    status: row.paymentStatus || row.status,
                    title: row.title || row.eventId,
                    visibility: row.visibility,
                    live: row.live,
                    duration: renderDuration(row),
                };
                return updatedRow;
            });
            console.log('filtered', filtered, doc.data);
            setDoc(filtered || null);
        } else {
            setDoc(null);
        }
        setLoaded(true);
    };

    useEffect(() => {
        let params = {
            collection: getTenantId('posts'),
            where: {
                fieldPath: 'status',
                optStr: 'in',
                value: ['ended', 'cancelled', 'started']
            },
            where2: {
                fieldPath: fieldName,
                optStr: '==',
                value: get(record, source)
            },
            orderBy: {
                fieldPath: 'startDate',
                directionStr: 'desc'
            }
        };

        firebaseClient
            .getAllSearchObject(params)
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            });
        return () => { };
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            sortable: false
        },
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 300,
            maxWidth: 400,
            flex: 1,
            sortable: false,
            renderCell: (params) => <div className="data-cell__copy-to-clipboard"><span>{params.id}</span><CopyToClipboardButton string source={params.id} className="launch-link" /></div>

        },
        {
            field: 'author',
            headerName: 'Author',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            sortable: false
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            sortable: false
        },
        {
            field: 'visibility',
            headerName: 'Type',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            sortable: false
        },
        {
            field: 'live',
            headerName: 'Live',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: (params) => params.value ? <CheckIcon /> : <CloseIcon />
        },
        {
            field: 'date',
            headerName: 'Start date',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            sortable: false
        },
        {
            field: 'duration',
            headerName: 'Duration',
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            sortable: false
        },


    ];

    return record && record.id && doc ? (
        <div style={{ display: 'flex', height: '100%', minHeight: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {!doc || !doc.length ? (
                    'No shows found'
                ) : (
                    <React.Fragment>
                        <DataGrid
                            disableColumnMenu={true}
                            sx={{
                                width: '100%',
                                height: 'auto',
                                border: 0,
                                '& .MuiDataGrid-columnSeparator--sideRight': {
                                    display: 'none'
                                },
                                '& .MuiDataGrid-columnHeaders + div': {
                                    height: 'auto !important',
                                    minHeight: '100px'
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    height: 'auto !important'
                                }
                            }}
                            getRowId={(row) => row.id}
                            rows={doc}
                            columns={columns}
                            pagination={false}
                            pageSize={100}
                            withBorder={false}
                            components={{
                                // Toolbar: CustomToolbar
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'startDate', sort: 'DESC' }]
                                }
                            }}
                            onRowClick={({ id }) => { redirect(`/posts/${id}/show`) }}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    ) : (
        <div>
            {error
                ? error
                : !loaded
                    ? "Loading..."
                    : "No shows found"}
        </div>
    );
};
